<template>
    <div class="relative" :class="{ '--error': !!getError, '--stackable': stackable }">
        <div class="flex flex-col" v-if="isRadioOrCheckbox">
            <label class="mt-6 mb-2 text-sm font-semibold" v-if="label && label.length" :class="{ '--error': !!getError }" :for="_uid">{{ label }}</label>
            <div>
                <template v-if="options">
                    <label class="relative flex items-center w-full p-3 transition-all border border-b-0 cursor-pointer min-h-15 bg-white last:border-b first:rounded-t last:rounded-b" :class="{ ...inputclasses }" v-for="(label, v) in options" :key="v">
                        <input class="h-0 appearance-none" :name="_uid" :type="type" :checked="value === v" @change="$emit('input', v)" />
                        <figure class="relative mr-2 -mt-px square-18">
                            <icon name="Checkbox Blank Circle Line" class="absolute transition-all duration-150 square-18 !fill-secondary peer-checked:bg-red" />
                            <icon name="Checkbox Circle Fill" class="absolute transition-all !fill-brand duration-150 scale-0 square-18" :class="{ 'scale-100': value === v }" />
                        </figure>
                        <data class="text-[0.9375rem] md:text-base tracking-tight font-semibold leading-4">{{ label }}</data>
                        <slot />
                    </label>
                </template>

                <template v-else>
                    <label class="relative flex items-center w-full p-3 transition-all border rounded cursor-pointer min-h-15 bg-white" :class="{ ...inputclasses }">
                        <input class="h-0 appearance-none" :name="_uid" :type="type" :checked="value" @change="$emit('input', !value)" />
                        <figure class="relative mr-2 -mt-px square-18">
                            <icon name="Checkbox Blank Circle Line" class="absolute transition-all duration-150 square-18 !fill-secondary peer-checked:bg-red" />
                            <icon name="Checkbox Circle Fill" class="absolute transition-all !fill-brand duration-150 scale-0 square-18" :class="{ 'scale-100': value }" />
                        </figure>
                        <data>
                            <slot />
                        </data>
                    </label>
                </template>
            </div>
        </div>

        <!-- dropdown -->
        <div v-else-if="isSelect && native">
            <div class="form-input !bg-white relative cursor-pointer">
                <label class="form-label font-medium" :for="_uid" :class="{ 'text-red-500': getError }">
                    <icon v-if="getError" class="!fill-red-500 square-16" name="Error Warning" />
                    <span>
                        <span>{{ label }}</span>
                        <span v-if="required && !getError"> * </span>
                        <span v-if="getError"> - {{ getError }}</span>
                    </span>
                </label>
                <div v-if="selectValue">{{ selectValue }}</div>
                <select class="appearance-none opacity-0 absolute right-0 top-0 bottom-0 left-0 w-full h-full cursor-pointer z-10" @change="$emit('input', $event.target.value)">
                    <option :selected="value === ''" disabled>{{ $t('Please select...') }}</option>
                    <option v-for="(label, v) in filteredOptions" :key="v" :value="v" :selected="value === v">{{ label }}</option>
                </select>
                <icon class="absolute right-3 square-30 top-[14px] transition-all" name="Arrow Drop Down Line" />
            </div>
        </div>

        <div v-else-if="isSelect">
            <div class="relative cursor-pointer">
                <label @click="toggleDropdown" class="cursor-pointer absolute z-10 text-sm leading-3 tracking-tight transition-all text-secondary top-3 left-3" :for="_uid" :class="{ 'text-red-500': getError }">
                    <icon v-if="getError" class="!fill-red-500 square-16" name="Error Warning" />
                    <span>
                        <span>{{ label }}</span>
                        <span v-if="required && !getError"> * </span>
                        <span v-if="getError"> - {{ getError }}</span>
                    </span>
                </label>

                <!-- Shown if the user wants to enter own option -->
                <div v-if="overwritten" class="relative w-full text-[0.9375rem] md:text-base tracking-tight font-semibold leading-4 placeholder-transparent transition-all rounded flex items-center min-h-15 peer bg-white" :class="{ ...inputclasses, 'rounded-b-none': showDropdown }">
                    <input ref="input" class="form-input peer !bg-white" :value="value" @input="$emit('input', $event.target.value)" />
                    <icon @click="toggleDropdown" class="absolute right-3 square-30 top-[14px] transition-all" name="Arrow Drop Down Line" :class="{ 'rotate-180': showDropdown }" />
                </div>

                <!-- Shown by default -->
                <div v-else @click="toggleDropdown" class="relative w-full text-[0.9375rem] md:text-base tracking-tight font-semibold leading-4 placeholder-transparent transition-all rounded flex items-center min-h-15 peer bg-white" :class="{ ...inputclasses, 'rounded-b-none': showDropdown }">
                    <div class="form-input peer !bg-white" :class="{ 'border-red-500': getError }">{{ selectValue }}</div>
                    <icon class="absolute right-3 square-30 top-[14px] transition-all" name="Arrow Drop Down Line" :class="{ 'rotate-180': showDropdown }" />
                </div>

                <div v-if="showDropdown" class="absolute left-0 z-20 w-auto min-w-full top-full max-h-" :class="{ '--slide': slide }" v-on-clickaway="toggleDropdown">
                    <!-- Search is only visible with more than five options -->
                    <div class="relative overflow-hidden border border-t-0 last:rounded-b bg-white" v-if="options && Object.keys(options).length > 5">
                        <input type="search" class="bg-white block w-full p-4" v-model="search" placeholder="Filter..." />
                        <icon v-if="search" @click="search = ''" class="absolute right-3 square-20 top-[18px] opacity-25" name="Close Fill" />
                    </div>

                    <ul class="max-h-[244px] overflow-x-visible overflow-y-auto">
                        <!-- The filtered options -->
                        <li class="overflow-hidden border border-t-0 last:rounded-b" v-for="(label, v) in filteredOptions" :key="v">
                            <button
                                type="button"
                                class="relative w-full text-left text-[0.9375rem] md:text-base tracking-tight font-semibold leading-4 p-3 cursor-pointer min-h-15 bg-white"
                                :class="{ '!bg-gray-100': v === value }"
                                @click="
                                    overwritten = false;
                                    toggleDropdown();
                                    $emit('input', v);
                                "
                            >
                                {{ label }}
                                <icon v-if="v === value" class="absolute right-3 square-20 top-[20px] !fill-brand" name="Checkbox Circle Fill" />
                            </button>
                        </li>
                    </ul>

                    <!-- Allow user to overwrite -->
                    <div v-if="!overwritten && overwritable" class="overflow-hidden border border-t-0 last:rounded-b">
                        <button
                            type="button"
                            class="relative text-center w-full bg-gray-100 text-left text-[0.9375rem] md:text-base tracking-tight font-semibold leading-4 p-3 cursor-pointer min-h-15 bg-white"
                            @click="
                                $emit('input', '');
                                overwritten = !overwritten;
                                toggleDropdown();
                                test();
                            "
                        >
                            <icon class="right-1 square-20 relative" name="Add Circle Fill" /> {{ $t("Add") }}
                        </button>
                    </div>
                </div>
            </div>
            <span v-if="help" class="text-xs text-left block mt-2 text-secondary line-clamp-3">{{ help }}</span>
        </div>
        <!-- dropdown -->

        <div v-else-if="type === 'textarea'">
            <textarea :readonly="readonly" :disabled="disabled" :id="_uid" :value="value" :placeholder="placeholder" rows="4" class="form-input peer" @blur="hasFocus = false" @focus="hasFocus = true" @input="$emit('input', $event.target.value)" :class="{ ...inputclasses, 'border-red-500': !!getError, '!pt-[0.75rem]': !label }"></textarea>

            <label class="form-label flex items-center gap-1" :for="_uid" :class="{ 'text-red-500': getError }">
                <icon v-if="getError" class="!fill-red-500 square-16" name="Error Warning" />
                <span>
                    <span>{{ label }}</span>
                    <span v-if="required && !getError"> * </span>
                    <span v-if="getError"> - {{ getError }}</span>
                </span>
            </label>

            <span v-if="help" class="text-xs text-left block mt-2 text-secondary line-clamp-3">{{ help }}</span>
        </div>

        <div v-else-if="type === 'file'" class="relative">
            <div v-if="preview" :class="{ ...inputclasses, '!border-red-500': getError }" class="form-input peer mt-4">
                <div class="relative mt-2">
                    <button
                        type="button"
                        @click="
                            $emit('input', null);
                            preview = null;
                        "
                        class="absolute top-4 right-4 bg-brand text-white uppercase tracking-wide rounded px-2 text-xs"
                    >
                        {{ $t("Remove") }}
                    </button>
                    <img :src="preview" alt="" class="rounded" />
                </div>
            </div>
            <div v-else :class="{ ...inputclasses, '!border-red-500': getError }" class="form-input peer">
                {{ $t("Nothing selected yet...") }}
                <input ref="input" :accept="accept" class="inset-0 absolute w-full opacity-0" :readonly="readonly" :disabled="disabled" :id="_uid" :type="type" @blur="hasFocus = false" @focus="hasFocus = true" @change="onFileChange" />
            </div>
            <label class="form-label" :for="_uid" :class="{ '!text-brand': getError }">{{ label }}</label>
            <div class="mt-1" v-if="getError">
                <icon class="!fill-brand square-14 -mt-px mr-1" name="Error Warning" /><span class="text-sm tracking-tight text-brand text-medium">{{ getError }}</span>
            </div>
            <span v-if="help" class="text-xs text-left block mt-2 text-secondary line-clamp-3">{{ help }}</span>
        </div>

        <div v-else-if="type === 'date'" class="max-w-sm">
            <label>
                <span class="mx-label flex items-center gap-1" :class="{ '!top-3': value, '!top-3 font-medium top-3 !text-brand leading-3 text-sm': hasFocus, 'text-red-500': getError }">
                    <icon v-if="getError" class="!fill-red-500 square-16" name="Error Warning" />
                    <span>
                        <!-- <span>{{ label }}</span> -->
                        <span v-if="required && !getError"> * </span>
                        <span v-if="getError"> - {{ getError }}</span>
                    </span>
                </span>
            </label>

                <date-picker
                    inline
                    ref="input"
                    :class="{ ...inputclasses, error: getError, 'has-focus': hasFocus }"
                    :readonly="readonly"
                    :disabled="disabled"
                    :id="_uid"
                    :value="value"
                    :placeholder="placeholder"
                    :type="type"
                    @blur="hasFocus = false"
                    :disabled-date="disabledDate"
                    value-type="YYYY-MM-DD"
                    format="DD-MM-YYYY"
                    @focus="hasFocus = true"
                    @input="$emit('input', $event)" />

            <span v-if="help" class="text-xs text-left block mt-2 text-secondary line-clamp-3">{{ help }}</span>
        </div>

        <div v-else>
            <input ref="input" :class="{ ...inputclasses, '!border-red-500': getError }" class="form-input peer !bg-white" :readonly="readonly" :disabled="disabled" :id="_uid" :value="value" :placeholder="placeholder" :type="type" @blur="hasFocus = false" @focus="hasFocus = true" @input="$emit('input', $event.target.value)" />

            <label class="form-label flex items-center gap-1 z-10" :for="_uid" :class="{ 'text-red-500': getError }">
                <icon v-if="getError" class="!fill-red-500 square-16" name="Error Warning" />
                <span>
                    <span>{{ label }}</span>
                    <span v-if="required && !getError"> * </span>
                    <span v-if="getError"> - {{ getError }}</span>
                </span>
            </label>

            <span v-if="help" class="text-xs text-left block mt-2 text-secondary line-clamp-3">{{ help }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { directive as onClickaway } from "vue-clickaway";
import ProductOption from "@/components/ProductOption.vue";
import FormError from "@/components/Form/FormError.vue";
import DatePicker from "vue2-datepicker";
import "@/icons/Arrow Drop Down Line";
import "@/icons/Checkbox Circle Fill";
import "@/icons/Checkbox Blank Circle Line";
import "@/icons/Error Warning";
import "@/icons/Add Circle Fill";
import "@/icons/Close Fill";
import dayjs from "@/tools/dates";

export default Vue.extend({
    props: {
        label: { default: "" },
        placeholder: { default: "" },
        value: [String, Boolean, Number, Array, Object, File],
        disabled: { type: Boolean, default: false },
        maxSize: { type: Number, default: 1 },
        accept: { type: String, default: "image/*" },
        options: Object,
        help: { default: null },
        overwritable: { type: Boolean, default: false },
        disabledDates: { type: Array, default: () => [] },
        error: String,
        type: {
            type: String,
            default: "text",
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        native: {
            type: Boolean,
            default: false,
        },
        inputClass: {
            type: String,
            default: "",
        },
        slide: { type: Boolean, default: false },
        stackable: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
    },

    components: {
        ProductOption,
        FormError,
        DatePicker,
    },

    directives: {
        onClickaway: onClickaway,
    },

    data: () => ({
        showDropdown: false,
        search: "",
        overwritten: false,
        preview: null,
        hasFocus: false,
        showError: false,
    }),

    watch: {
        hasFocus(value) {
            this.$emit(value ? "focus" : "blur");
        },
    },

    methods: {
        toggleDropdown(): void {
            this.showDropdown = !this.showDropdown;
        },

        disabledDate(d: Date): any {
            return this.correctDisabledDates && this.correctDisabledDates.length && !this.correctDisabledDates.includes(d.toISOString().slice(0, 10));
        },

        test(): void {
            setTimeout(() => (this.$refs.input as HTMLElement).focus(), 50);
        },

        onFileChange(e: any): void {
            const files = e.target.files || e.dataTransfer.files;

            if (!files.length) {
                return;
            }

            const file = files[0];
            const size = file.size && file.size / Math.pow(1000, 2);

            // if (size > this.maxSize) {
            //     return;
            // }

            this.$emit("input", file);

            const reader = new FileReader();

            reader.onload = (e: any) => {
                const dataURI = e.target.result;

                if (dataURI) {
                    this.preview = dataURI;
                }
            };

            reader.readAsDataURL(file);
        },
    },

    mounted(): void {
        if (this.autofocus) {
            (this.$refs.input as HTMLElement).focus();
        }

        if (this.type === 'date' && this.disabledDates.length && !this.value) {
            this.$emit('input', this.disabledDates[0])
        }
    },

    computed: {
        correctDisabledDates(): string[] {
            return this.disabledDates.map((date: any) => dayjs(date).subtract(1, "day").format("YYYY-MM-DD"));
        },
        getError(): any {
            return Array.isArray(this.error) ? this.error[0] : this.error;
        },
        filteredOptions(): any {
            return Object.keys(this.options)
                .filter((key) => this.options[key].toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
                .reduce((res: any, key: any) => ((res[key] = this.options[key]), res), {});
        },

        isRadioOrCheckbox(): boolean {
            return ["radio", "checkbox"].indexOf(this.type) !== -1;
        },

        isSelect(): boolean {
            return this.type === "select";
        },

        selectValue(): string | void {
            if (!this.isSelect) return;

            if (this.value) {
                return this.options[<string>this.value];
            }

            if (this.placeholder) {
                return this.placeholder;
            }

            return this.$t("Please select...").toString();
        },

        inputclasses(): any {
            const output: any = {};
            output[this.inputClass] = true;
            return output;
        },
    },
});
</script>

<style lang="stylus" scoped>

>>>  .mx-calendar {
      width: 100% !important;
}

>>> .mx-input {
    background: var(--primary) !important;
    box-shadow: none;
    border-color: inherit !important;
    border-radius var(--radius) !important;
}

>>> .has-focus {
    .mx-input {
        border-color: var(--brand) !important;
    }
}

 >>> .error {
     .mx-input {
         border-color: rgb(239, 68, 68) !important;
     }
 }
</style>
