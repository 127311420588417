/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ri:check-fill': {
    width: 1,
    height: 1,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"/>'
  }
})
