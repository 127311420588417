/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'List Check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" _fill="#000" fill-rule="nonzero"/>'
  }
})
