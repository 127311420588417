/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Diagonal Arrow Right UP Outline': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 7.05a1 1 0 00-1-1L9 6a1 1 0 100 2h5.56l-8.27 8.29a1 1 0 000 1.42 1 1 0 001.42 0L16 9.42V15a1 1 0 102 0z" _fill="#231F20"/>'
  }
})
