/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Arrow Drop Right Line': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.172 12l-2.83-2.83 1.41-1.415 4.243 4.24-4.243 4.24-1.42-1.42z" _fill="#000" fill-rule="nonzero"/>'
  }
})
