<template>
    <styled-button dim:hover v-if="$store.state.domain.chat && !loaded" @click="loadChat" class="Chat" background>
        <icon v-if="!loading" width="20px" name="Questionnaire Fill" />

        <icon v-if="loading" width="24px" class="rotate" name="Loader 3 Fill" />
        <span>{{ loading ? $t('Connecting...') : $t('Help') }}</span>

    </styled-button>
</template>

<script lang="ts">
import Vue from "vue";
import '@/icons/Loader 3 Fill'
import '@/icons/Questionnaire Fill'

declare var window: any;
declare var Ze: any;
declare var zE: any;

window.loadChat = function (callback: any) {
    if (!!window.Ze) {
        return true;
    }

    let script = document.createElement("script");
    script.src = "https://static.zdassets.com/ekr/snippet.js?key=9bfe58f4-673a-439f-a2c5-bdfee81d0dd1";
    script.id = "ze-snippet"
    script.onload = callback
    document.body.appendChild(script);
};

export default Vue.extend({
    data: () => ({
        loaded: false,
        loading: false
    }),

    methods: {
        chatLoaded(): void {
            zE(() => {
                zE.activate();

                zE('webWidget:on', 'userEvent', (event: any) => {
                    if (event.action === 'Web Widget Opened') {
                        this.loaded = true
                    }
                })
            });
        },

        loadChat(): void {
            this.loading = true

            window.loadChat(this.chatLoaded)
        }
    }
})
</script>

<style lang="stylus">
.Chat
    position fixed
    bottom 20px
    right 20px
    z-index 10
    border-radius 10rem
    padding 8px 18px
    color white
    font-size 17px
    display flex
    align-items center
    cursor pointer

    +below(768px)
        bottom 80px
        right 20px
        height 48px
        width 48px
        padding 0px
        display block

        span
            display none

    svg
        fill white
        color white
        display inline-block
        margin-left 0px

    span
        margin-left 0px
        font-family var(--display)
        line-height 16px
        font-weight 700
        letter-spacing -0.2px
        margin-left 8px
</style>
