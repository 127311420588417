<template>
    <theme-provider id="app" class="bg-neutral-50" :class="'page ' + $route.name" :theme="theme">
        <mobile-app-notification />

        <component v-if="this.$route.meta.layout" :is="this.$route.meta.layout">
            <router-view />
        </component>

        <platform v-else>
            <router-view />
        </platform>

        <notifications position="top right">
            <template slot="body" slot-scope="props">
                <div :class="props.item.type" class="flex rounded-full h-12 justify-center z-999 text-white md:rounded-none bg-brand-900/90 backdrop-blur-sm">
                    <div @click="props.close" class="flex gap-2 items-center pl-3 py-2 pr-5">
                        <figure class="-mt-1" :class="{ '': props.item.type === 'success' }" @click="error = false">
                            <icon v-if="props.item.type === 'success'" name="checkbox-circle-fill" class="w-4 aspect-square" />
                            <icon v-else-if="props.item.type === 'error'" name="Error Warning" class="w-4 aspect-square" />
                            <icon v-else name="Information Fill" class="w-4 aspect-square" />
                        </figure>
                        <h3 class="-mt-px text-[14px] font-semibold leading-4">{{ props.item.title }} {{ props.item.text }}</h3>
                    </div>
                </div>
            </template>
        </notifications>
    </theme-provider>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
const Platform = () => import("@/components/Layouts/Platform.vue");
import MobileAppNotification from "@/components/MobileAppNotification.vue";
import Chat from "@/components/Chat.vue";
import Notifications from "vue-notification";
import { ThemeProvider } from "vue-styled-components";
import "@/icons/Error Warning";
import "@/icons/checkbox-circle-fill";
import "@/icons/Information Fill";
import "@/icons/share-outline";
import "@/icons/add-square";

Vue.use(Notifications);

declare var message: any;

export default Vue.extend({
    components: {
        ThemeProvider,
        Platform,
        MobileAppNotification,
        Chat,
    },
    methods: {
        setViewHeight: function () {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        },
    },

    created(): void {
        this.$store.dispatch("init");
    },

    mounted(): void {
        if (this.$store.state.darkMode) {
            document.documentElement.setAttribute("data-theme", "darkMode");
            localStorage.setItem("theme", "darkMode");
        }

        if (message) {
            Vue.notify({
                type: Object.keys(message)[0],
                text: <string>Object.values(message)[0],
                duration: 1000,
            });
        }

        this.setViewHeight();
        window.addEventListener("resize", () => {
            this.setViewHeight();
        });
    },

    watch: {
        "$store.state.darkMode"(value: boolean) {
            if (value) {
                document.documentElement.setAttribute("data-theme", "darkMode");
                localStorage.setItem("theme", "darkMode");
            } else {
                document.documentElement.removeAttribute("data-theme");
                localStorage.removeItem("theme");
            }
        },
    },

    computed: {
        theme(): { [key: string]: string } {
            return this.$store.getters.theme;
        },

        darkMode(): boolean {
            return this.$store.state.darkMode;
        },
    },
});
</script>

<style lang="stylus">
@require '../styles/app.styl'

.page
    +option('map')
        +below(768px)
            .MainMenu
                display none

            .Platform__main
                padding-top 0 !important

@keyframes spin
    100%
        -webkit-transform rotate(360deg)
        transform rotate(360deg)

.rotate
    animation spin 3s linear infinite
    margin-left 8px

.vue-notification-group
    width auto !important
    right 24px !important
    /* left 0 !important */
    top 24px !important


    +below(768px)
        left 0 !important
        top 0px !important
        right 0 !important
        width 100vw !important

.vue-notification-wrapper
    width auto !important
    padding-top 20px


    +below(768px)
        padding-top 0px

.fade-enter-active, .fade-leave-active
    transition opacity 0.25s

.fade-enter, .fade-leave-to
    opacity 0

.fade-slow-enter-active, .fade-slow-leave-active
    transition opacity 1s

.fade-slow-enter, .fade-slow-leave-to
    opacity 0

.vb > .vb-dragger
    z-index 5
    width 12px
    right 0

.vb > .vb-dragger > .vb-dragger-styler
    backface-visibility hidden
    transform rotate3d(0, 0, 0, 0)
    transition background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out
    background-color rgba(#333, 0)
    margin 5px 5px 5px 0
    border-radius 20px
    height calc(100% - 10px)
    display block

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler
    background-color rgba(#333, 0.5)

.vb:hover > .vb-dragger > .vb-dragger-styler
    background-color rgba(#333, 0.5)
    height 100%

.vb > .vb-dragger:hover > .vb-dragger-styler
    background-color rgba(#333, 0.5)
    margin 0px
    height 100%

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler
    background-color rgba(#333, 0.5)
    margin 0px
    height 100%

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler
    background-color rgba(#333, 0.5)
</style>
