/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Questionnaire Fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.455 19L2 22.5V4c0-.56.44-1 1-1h18c.55 0 1 .44 1 1v14c0 .55-.45 1-1 1H6.455zM11 14v2h2v-2h-2zM8.567 8.81l1.96.39a1.493 1.493 0 112.93.58c-.15.7-.76 1.2-1.48 1.2h-1v2h1a3.504 3.504 0 00-.02-7.01c-1.67 0-3.11 1.17-3.44 2.81l.05.03z" _fill="#000" fill-rule="nonzero"/>'
  }
})
