/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Send Plane': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.946 9.31c-.522-.18-.53-.46.01-.64L21.043 2.3c.52-.18.83.12.68.63l-5.46 19.086c-.15.52-.46.54-.68.04l-3.6-8.09 6-8-8 6L1.92 9.27l.026.04z" _fill="#000" fill-rule="nonzero"/>'
  }
})
