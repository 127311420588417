<template>
  <styled color v-if="error" class="Input__error" :class="{ '--error': !!error }">
    <icon class="Input__icon" width="14px" height="14px" name="Error Warning" /><span>{{ error }}</span>
  </styled>
</template>

<script lang="ts">
import Vue from 'vue'
import '@/icons/Error Warning'

export default Vue.extend({
  props: {
    error: String
  }
})
</script>

<style lang="stylus" scoped>
.Input__icon
  position relative
  display block !important

.Input__error
  display flex
  padding-top 8px
  align-items center
  line-height 15px
  font-weight 500
  font-size 14px

  svg
    margin-top -1px
    margin-right 6px
</style>