/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Star S Fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 17l-5.878 3.59 1.59-6.7-5.23-4.48 6.86-.55 2.64-6.36 2.64 6.36 6.866.55-5.24 4.48 1.59 6.7z" _fill="#000" fill-rule="nonzero"/>'
  }
})
