/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Copper Coin Fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-14.25l-4.243 4.24L12 16.23l4.24-4.25-4.25-4.243.01.013z" _fill="#000" fill-rule="nonzero"/>'
  }
})
