/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Plus Circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#231F20" d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm3 11h-2v2a1 1 0 11-2 0v-2H9a1 1 0 110-2h2V9a1 1 0 112 0v2h2a1 1 0 110 2z"/>'
  }
})
