<template>
    <div class="text-sm text-neutral-400 tracking-tight flex-grow" :class="{'text-center p-8': center}">
        <icon name="Loader 3 Fill" class="rotate relative -top-[1px]" width="20" /> <slot>{{ $t('Loading, please wait...') }}</slot>
    </div>
</template>

<script lang="ts">
import "@/icons/Loader 3 Fill";
import Vue from 'vue';

export default Vue.extend({
    props: {
        center: {
            type: Boolean,
            default: false
        }
    }
})
</script>
