<template>
    <auth-form @submit="handle">
        <template #header>
            <div class="mb-10">
                <h1 class="text-5xl tracking-tight font-semibold font-display">{{ sso.title }}</h1>
                <h2 class="tracking-tight mt-2 text-md text-secondary">{{ sso.intro }}</h2>
            </div>
        </template>

        <form-input class="mt-4" input-class="!border-0 !p-0 !rounded-none !tracking-tight !text-1sm !min-h-min" type="checkbox" v-model="tos">
            <i18n tag="span" :path="getTosText">
                <a slot="tos" class="font-medium underline text-brand" :href="$store.state.store.files.tos" target="_blank">{{ $t('Terms & Conditions') }}</a>
                <a slot="privacy" class="font-medium underline text-brand" :href="$store.state.store.files.privacy" target="_blank">{{ $t('Privacy Policy') }}</a>
            </i18n>
        </form-input>

        <div class="mt-6">
            <button :disabled="!tos" class="justify-center w-full font-semibold button-primary h-14 text-md disabled:bg-secondary font-display" type="submit">
                <span>{{ sso.button }}</span>
            </button>
        </div>

        <div  class="mt-4 text-center cursor-pointer" @click="toggleModal">
            <span class="font-medium tracking-tight select-none text-1sm text-brand hover:underline">{{ $t('Learn more about this platform') }}</span>
        </div>

        <!-- Modal -->
        <div v-if="modal" class="fixed inset-0 overflow-y-auto bg-brand-900/50 z-70 animate-fade backdrop-filter">
            <div class="absolute inset-0 flex justify-center" @click.self="toggleModal" ref="modal">
                <div class="flex flex-col min-h-screen">
                    <aside class="flex-grow" @click.self="toggleModal" ref="modal" />
                    <!-- modal -->
                    <main data-modal class="animate-modal max-w-[640px] w-[640px] rounded justify-between bg-white mx-auto md:mx-0 md:!min-w-screen md:w-screen md:max-w-screen md:rounded-b-none shadow">
                        <nav class="sticky inset-0 top-0 z-10 flex items-center h-16 border-b rounded-t bg-bg">
                            <h4 class="w-full text-lg font-bold tracking-tight text-center font-display" v-text="$t('About this platform')" />
                            <button class="absolute left-5 text-brand hover:text-primary" @click="toggleModal">
                                <icon width="22px" name="Arrow Left" />
                            </button>
                        </nav>
                        <!-- content -->
                        <section class="p-0 overflow-hidden text-base tracking-tight text-left">
                            <dl class="px-6 py-12">
                                <dt class="text-3xl font-semibold leading-10 tracking-tight font-display">{{ $store.state.domain.title }}</dt>
                                <dd class="max-w-lg tracking-tight text-md text-secondary">{{ $store.state.domain.description }}</dd>
                            </dl>
                            <shelf header-class="pr-6" class="p-6 pr-0 mt-4 border-t" no-container nav-top v-if="categories.length" :title="$t('Featured')" :subtitle="$t('Create your account to discover more.')" :large="1.4" :medium="1.4" :small="1.1">
                                <service-card v-for="c in categories.slice(0, 4)" :key="c.id" :title="c.name" :catchfrase="c.catchfrase" :image="c.image" :responsive="c.media.image ? c.media.image.responsive.default : c.image" />
                            </shelf>
                            <div class="p-6 pt-0">
                                <div class="justify-center w-full font-medium button-primary h-14 text-md disabled:bg-secondary" @click="toggleModal">{{ $t('Close') }}</div>
                            </div>
                        </section>
                        <!-- /content -->
                    </main>
                    <!-- /modal -->
                    <aside class="flex-grow md:hidden" @click.self="toggleModal" ref="modal" />
                </div>
            </div>
        </div>
        <!-- / Modal -->
    </auth-form>
</template>

<script lang="ts">
import Vue from 'vue'
import axios from '@/services/Clients/AxiosClient'
import base64url from 'base64url'
import AuthButton from './Parts/Auth/AuthButton.vue'
import AuthForm from './Parts/Auth/AuthForm.vue'
import mapValues from 'lodash/mapValues'
import { TranslateResult } from 'vue-i18n'
import '@/icons/Arrow Forward'
import '@/icons/Alert Circle'
import '@/icons/Loader 3 Fill'
import '@/icons/Email'
import '@/icons/Send Plane'
import Disclosure from '@/components/Elements/Disclosure.vue'
import Shelf from '@/components/Shelf.vue'
import ServiceCard from '@/components/Cards/ServiceCard.vue'
import Error from '@/components/Error.vue'

interface Data {
    error: string,
    type: string | null,
    errors: {},
    tos: boolean
    modal: boolean
    categories: {},
}

declare var data: any;

export default Vue.extend({
    components: {
        AuthButton, AuthForm,
        Disclosure, ServiceCard, Shelf,
        Error
    },

    data: (): Data => ({
        type: null,
        error: '',
        errors: {},
        tos: false,
        modal: false,
        categories: [],
    }),

    created(): void {
        let next: any = <string>new URLSearchParams(window.location.search).get('next')

        if (next) {
            localStorage.setItem('nextUrl', next)
        }

        if (data.error) {
            this.error = data.error
        }

        axios.get('/api/v2/categories')
            .then(response => this.categories = response.data.data)
    },

    computed: {
        sso(): any {
            return this.$store.state.domain.sso
        },

        getTosText(): string {
            if (this.sso.label) {
                return this.sso.label
            }

            return this.$store.state.domain && this.$store.state.domain.tos ? this.$store.state.domain.tos : this.$t('I accept the {tos} and {privacy}').toString()
        }
    },

    methods: {
        handle() {
            window.location.href = '/saml2/auth/login'
        },

        toggleModal() {
            this.modal = !this.modal
        },
    }
})
</script>
