<template>
    <section class="relative flex flex-col justify-center h-full bg-neutral-50" v-if="$store.state.domain">
        <figure class="absolute inset-0 image-cover" v-if="$store.state.domain.media.registration" v-html="$store.state.domain.media.registration.responsive.large"></figure>
    </section>
</template>

<script lang="ts">
import Vue from 'vue'
import styled from 'vue-styled-components'
import axios from '@/services/Clients/AxiosClient'
import '@/icons/Diagonal Arrow Right UP Outline'
import ServiceCard from '@/components/Cards/ServiceCard.vue'
import Shelf from '@/components/Shelf.vue'

export default Vue.extend({
    components: { ServiceCard, Shelf },
    data: () => ({
        categories: [],
        domain: null
    }),

    created(): void {
        axios.get('/api/v2/categories')
            .then(response => this.categories = response.data.data)
    }
})
</script>
