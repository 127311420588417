/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Upload Cloud Fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7 20.98l-.01-.01A6.512 6.512 0 011 13.99a6.505 6.505 0 013.04-5.03h-.01c.56-4.39 4.57-7.49 8.95-6.92a7.98 7.98 0 016.91 6.91 6.498 6.498 0 012.08 8.95 6.531 6.531 0 01-5.03 3.04v.01H6.93v-.02l.07.05zm6-7.99h3l-4-5-4 5h3v4h2v-4z" _fill="#000" fill-rule="nonzero"/>'
  }
})
