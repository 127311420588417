<template>
    <flex class="Flex" :class="getClasses">
        <slot />
    </flex>
</template>

<script lang="ts">
import Vue from 'vue'
import styled from 'vue-styled-components'

const Flex = styled.div`
    display: flex
`

export default Vue.extend({
    props: {
        row: Boolean,
        column: Boolean,
        spaceBetween: Boolean,
        center: Boolean,
        grid: Boolean,
        container: Boolean
    },

    components: {
        Flex
    },

    computed: {
        getClasses(): string {
            var classes: string[] = []

            if (this.row) classes.push('row')
            if (this.column) classes.push('column')
            if (this.center) classes.push('center')
            if (this.spaceBetween) classes.push('space-between')
            if (this.grid) classes.push('grid')
            if (this.container) classes.push('grid')

            return classes.map((item: string) => '--' + item).join(' ')
        }
    }
})
</script>

<style lang="stylus" scoped>
.Flex
    width 100%
    display flex

    +option('center')
        align-items center

    +option('space-between')
        justify-content space-between

    +option('row')
        lost-flex-container 100%

        >>> .Flex__column
            lost-column 1 / 1 1 16px flex

            +above(1024px)
                +option('md-half')
                    lost-column 1 / 2 2 40px flex

                +option('md-third')
                    lost-column 1 / 3 3 40px flex

                +option('md-fourth')
                    lost-column 1 / 4 4 40px flex

    +option('grid')
        lost-flex-container 100%

        >>> .Flex__column
            lost-waffle 1 / 1 1 40px flex

            +above(768px)
                +option('md-third')
                    lost-waffle 1 / 2 2 32px flex

            +above(1024px)
                +option('md-half')
                    lost-waffle 1 / 2 2 40px flex

                +option('md-third')
                    lost-waffle 1 / 3 3 40px flex

                +option('md-fourth')
                    lost-waffle 1 / 4 4 40px flex
</style>