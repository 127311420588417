import Vue from "vue";
import { i18n } from "./i18n";
import Router, { RawLocation, Route } from "vue-router";
import trim from "lodash/trim";
import vueStore from "@/store/store";
import Auth from "../views/Auth.vue";
import AuthSso from "../views/AuthSso.vue";
import Onboarding from "../components/Layouts/Onboarding.vue";
import Blank from "../components/Layouts/Blank.vue";
import VueRouter from "vue-router";

Vue.use(Router);

declare var td: any;
declare var store: any;

export const router = new Router({
    mode: "history",
    linkActiveClass: "--active",
    linkExactActiveClass: "--exact",

    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
            };
        }

        if (savedPosition) {
            return savedPosition;
        }

        if (!to.meta!.showModal && !from.meta!.showModal) {
            return { x: 0, y: 0 };
        }
    },

    routes: [
        {
            path: "/",
            name: "home",
            component: () => import("../views/Home.vue"),
            meta: {
                title: store.name,
                pageTitle: i18n.t("Dashboard"),
            },
        },
        {
            path: "/map",
            name: "map",
            component: () => import("../views/Map.vue"),
            meta: {
                title: i18n.t("Map - {store}", { store: store.name }),
                pageTitle: i18n.t("Map"),
            },
        },
        {
            path: "/services",
            name: "services",
            component: () => import("../views/Categories.vue"),
            meta: {
                title: i18n.t("Services - {store}", { store: store.name }),
                pageTitle: i18n.t("Services"),
            },
        },
        {
            path: "/services/:slug-:category(\\d+)",
            name: "service",
            components: {
                default: () => import("../views/Categories.vue"),
                modal: () => import("../views/Category.vue"),
            },
            meta: {
                title: i18n.t("The Office Service"),
                pageTitle: i18n.t("Services"),
                showModal: true,
            },
        },
        {
            path: "/store",
            name: "store",
            component: () => import("../views/Shops.vue"),
            meta: {
                title: i18n.t("Store - {store}", { store: store.name }),
                pageTitle: i18n.t("Store"),
            },
        },
        {
            path: "/store/:slug-:supplier(\\d+)",
            name: "supplier",
            components: {
                default: () => import("../views/Shops.vue"),
                modal: () => import("../views/Shop.vue"),
            },
            meta: {
                title: i18n.t("The Office Service"),
                pageTitle: i18n.t("Store"),
                showModal: true,
            },
        },
        {
            path: "/surveys",
            name: "surveys",
            component: () => import("../features/Survey/pages/Surveys.vue"),
            meta: {
                title: i18n.t("Surveys - {store}", { store: store.name }),
                pageTitle: i18n.t("Store"),
            },
        },
        {
            path: "/surveys/:survey",
            name: "surveys.show",
            component: () => import("../features/Survey/pages/Survey.vue"),
            meta: {
                title: i18n.t("Surveys - {store}", { store: store.name }),
                pageTitle: i18n.t("Store"),
            },
        },
        {
            path: "/review/:order",
            name: "review",
            component: () => import("../features/Review/Review.vue"),
            meta: {
                layout: Blank,
                title: i18n.t("Review - {store}", { store: store.name }),
                pageTitle: i18n.t("Store"),
            },
        },
        {
            path: "/events",
            name: "events",
            component: () => import("../views/Events.vue"),
            meta: {
                title: i18n.t("Events - {store}", { store: store.name }),
            },
        },
        {
            path: "/check-in",
            name: "checkin",
            component: () => import("../views/Checkin.vue"),
            meta: {
                title: i18n.t("Checkin - {store}", { store: store.name }),
                layout: Blank,
            },
        },
        {
            path: "/challenges",
            name: "challenges",
            redirect: (to) => {
                return { path: "/vitality" };
            },
        },

        {
            path: "/vitality",
            name: "vitality",
            component: () => import("../views/Vitality.vue"),
            meta: {
                title: i18n.t("Vitality - {store}", { store: store.name }),
            },
        },

        {
            path: "/challenges/:slug-:category(\\d+)",
            name: "challenge",
            redirect: (to) => {
                return { name: "vitality-challenge", params: to.params };
            },
        },
        {
            path: "/vitality/:slug-:category(\\d+)",
            name: "vitality-challenge",
            components: {
                default: () => import("../views/Vitality.vue"),
                modal: () => import("../views/Challenge.vue"),
            },
            meta: {
                title: i18n.t("Challenge - {store}", { store: store.name }),
                showModal: true,
            },
        },
        {
            path: "/settings",
            name: "settings",
            component: () => import("../views/Settings/Index.vue"),
            meta: {
                title: i18n.t("Order - {store}", { store: store.name }),
                pageTitle: i18n.t("Order"),
            },
        },
        {
            path: "/settings/profile",
            name: "profile",
            component: () => import("../views/Settings/Profile.vue"),
            meta: {
                title: i18n.t("Profile - {store}", { store: store.name }),
                pageTitle: i18n.t("Profile"),
            },
        },
        {
            path: "/settings/appointments",
            name: "appointments",
            component: () => import("../views/Settings/Appointments.vue"),
            meta: {
                title: i18n.t("Appointments - {store}", { store: store.name }),
                pageTitle: i18n.t("Appointments"),
            },
        },
        {
            path: "/settings/appointments/:order",
            name: "settings.appointment",
            components: {
                default: () => import("../views/Settings/Appointments.vue"),
                modal: () => import("../views/Order.vue"),
            },
            meta: {
                title: i18n.t("Orders - {store}", { store: store.name }),
                pageTitle: i18n.t("Orders"),
                showModal: true,
            },
        },
        {
            path: "/settings/billing",
            name: "billing",
            component: () => import("../views/Settings/Billing.vue"),
            meta: {
                title: i18n.t("Billing - {store}", { store: store.name }),
                pageTitle: i18n.t("Billing"),
            },
        },
        {
            path: "/settings/orders",
            name: "orders",
            component: () => import("../views/Settings/Orders.vue"),
            meta: {
                title: i18n.t("Orders - {store}", { store: store.name }),
                pageTitle: i18n.t("Orders"),
            },
        },
        {
            path: "/settings/notifications",
            name: "notifications",
            component: () => import("../views/Settings/Notifications.vue"),
            meta: {
                title: i18n.t("Notifications - {store}", { store: store.name }),
                pageTitle: i18n.t("Notifications"),
            },
        },
        {
            path: "/settings/connections",
            name: "connections",
            component: () => import("../views/Settings/Connections.vue"),
            meta: {
                title: i18n.t("Connections - {store}", { store: store.name }),
                pageTitle: i18n.t("Connections"),
            },
        },
        {
            path: "/settings/wallet",
            name: "wallet",
            component: () => import("../views/Settings/Wallet.vue"),
            meta: {
                title: i18n.t("Wallet - {store}", { store: store.name }),
                pageTitle: i18n.t("Wallet"),
            },
        },
        {
            path: "/settings/rewards",
            name: "rewards",
            component: () => import("../views/Settings/Rewards.vue"),
            meta: {
                title: i18n.t("Rewards - {store}", { store: store.name }),
                pageTitle: i18n.t("rewards"),
            },
        },
        {
            path: "/settings/orders/:order",
            name: "settings.order",
            components: {
                default: () => import("../views/Settings/Orders.vue"),
                modal: () => import("../views/Order.vue"),
            },
            meta: {
                title: i18n.t("Orders - {store}", { store: store.name }),
                pageTitle: i18n.t("Orders"),
                showModal: true,
            },
        },
        {
            path: "/settings/billing/:order",
            name: "settings.billing",
            components: {
                default: () => import("../views/Settings/Billing.vue"),
                modal: () => import("../views/Order.vue"),
            },
            meta: {
                title: i18n.t("Billing - {store}", { store: store.name }),
                pageTitle: i18n.t("Billing"),
                showModal: true,
            },
        },
        {
            path: "/hello/:step?",
            name: "hello",
            components: {
                default: () => import("../views/Home.vue"),
                modal: () => import("../views/Walkthrough.vue"),
            },
            meta: {
                title: i18n.t("Walkthrough - {store}", { store: store.name }),
                pageTitle: i18n.t("Walkthrough"),
                showModal: true,
            },
        },
        {
            path: "/login",
            name: "login",
            component: Auth,
            meta: {
                layout: Onboarding,
                title: i18n.t("Login - {store}", { store: store.name }),
            },
        },
        {
            path: "/login/sso",
            name: "login.sso",
            component: AuthSso,
            meta: {
                layout: Onboarding,
                title: i18n.t("Login - {store}", { store: store.name }),
            },
        },
        {
            path: "/loginwithemail",
            name: "loginwithemail",
            component: Auth,
            meta: {
                layout: Onboarding,
                title: i18n.t("Login - {store}", { store: store.name }),
            },
        },
        {
            path: "/register",
            name: "register",
            component: Auth,
            meta: {
                layout: Onboarding,
                title: i18n.t("Register - {store}", { store: store.name }),
            },
        },
        {
            path: "/login",
            name: "login",
            component: Auth,
            meta: {
                layout: Onboarding,
                title: i18n.t("Login - {store}", { store: store.name }),
            },
        },
        {
            path: "/loginwithemail",
            name: "loginwithemail",
            component: Auth,
            meta: {
                layout: Onboarding,
                title: i18n.t("Login - {store}", { store: store.name }),
            },
        },
        {
            path: "/agenda/:day?",
            name: "agenda",
            component: () => import("../views/Agenda.vue"),
            meta: {
                title: i18n.t("Agenda - {store}", { store: store.name }),
                pageTitle: i18n.t("Agenda"),
            },
        },
        {
            path: "/manage/statistics",
            name: "manage.statistics",
            component: () => import("../views/Statistics.vue"),
            meta: {
                title: i18n.t("Statistics - {store}", { store: store.name }),
                pageTitle: i18n.t("Statistics"),
            },
        },
        {
            path: "/events/:event",
            name: "event",
            component: () => import("../views/Event.vue"),
            meta: {
                title: i18n.t("Event - {store}", { store: store.name }),
                pageTitle: i18n.t("Event"),
            },
        },
        {
            path: "/videos/:video",
            name: "video",
            component: () => import("../views/Video.vue"),
            meta: {
                title: i18n.t("Video - {store}", { store: store.name }),
                pageTitle: i18n.t("Video"),
            },
        },
        {
            path: "/videos",
            name: "videos",
            component: () => import("../views/Videos.vue"),
            meta: {
                title: i18n.t("Videos - {store}", { store: store.name }),
                pageTitle: i18n.t("Videos"),
            },
        },
        {
            path: "/blog/:slug",
            name: "post",
            component: () => import("../views/BlogPost.vue"),
            meta: {
                title: i18n.t("Post - {store}", { store: store.name }),
                pageTitle: i18n.t("Post"),
            },
        },
        // ADD SAFE PATH TO AppHandler
        {
            path: "*",
            redirect: "/",
        },
        {
            path: "/biogena/",
            name: "biogena",
            component: () => import("../views/Biogena/Start.vue"),
            meta: {
                title: i18n.t("Personalised vitamins - {store}", { store: store.name }),
                pageTitle: i18n.t("Personalised vitamin"),
            },
        },
        {
            path: "/biogena/connect",
            name: "biogena",
            component: () => import("../views/Biogena/Connect.vue"),
            meta: {
                title: i18n.t("Personalised vitamins - {store}", { store: store.name }),
                pageTitle: i18n.t("Personalised vitamin"),
            },
        },
    ],
});

router.beforeEach((to: Route, from: Route, next: Function) => {
    const publicPages = vueStore.state.domain.sso ? ["register", "reset", "forget", "loginwithemail", "login.sso"] : ["login", "register", "reset", "forget", "loginwithemail"];

    const isPublicPage = publicPages.includes(to.name as string);

    if (to.query.c) {
        const now = new Date();

        const item = {
            value: to.query.c,
            expiry: now.getTime() + 604800000,
        };

        localStorage.setItem("campaign", JSON.stringify(item));
    }

    if (to.name === "review") {
        return next();
    }

    if (isPublicPage && vueStore.getters.isAuthenticated) {
        return next("/");
    }

    if (!isPublicPage && vueStore.getters.isGuest) {
        localStorage.setItem("nextUrl", to.path);

        if (vueStore.state.domain.sso) {
            return next({ name: "login.sso", params: { nextUrl: to.path } });
        } else {
            return next({ name: "login", params: { nextUrl: to.path } });
        }
    }

    if (vueStore.getters.isAuthenticated && to.name !== "hello" && vueStore.state.user.full_name === "") {
        return next("/hello");
    }

    // redirect user if pointed to specific location
    if (to.redirectedFrom) {
        const isInt = /^\d+$/;

        let path = trim(to.redirectedFrom, "/").split("/");

        if (path.length && isInt.test(path[0])) {
            vueStore.commit("setWaitingLocation", parseInt(path[0]));

            path.shift();

            const safePaths = ["services", "store", "events", "settings", "login", "register", "password", "email", "agenda", "manage", "videos", "blog"];

            if (safePaths.includes(path[0])) {
                return next("/" + path.join("/"));
            }
        }
    }

    if (isPublicPage && vueStore.getters.isAuthenticated) {
        return next("/");
    }

    if (!isPublicPage && vueStore.getters.isGuest) {
        if (vueStore.state.domain.sso) {
            return next({ name: "login.sso", params: { nextUrl: to.path } });
        } else {
            return next({ name: "login", params: { nextUrl: to.path } });
        }
    }

    if (vueStore.getters.isAuthenticated && to.name !== "hello" && vueStore.state.user.full_name === "") {
        return next("/hello");
    }

    if (vueStore.getters.isAuthenticated && to.name !== "hello" && !vueStore.state.data.recurring && vueStore.state.store.security.recurring) {
        return next("/hello/payments");
    }

    if (to.meta!.showDetails) {
        window.scrollTo(0, 0);
        to.params.scrollTo = -window.scrollY + "px";
    }

    const nearestRouteWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestRouteWithTitle) document.title = nearestRouteWithTitle.meta.title;

    if (typeof td === "object") {
        td.sendPageView();
    }

    next();
});

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
    return new Promise((resolve, reject) => {
        originalPush.call(
            this,
            location,
            () => {
                resolve(this.currentRoute);
            },
            (error) => {
                if (error.name === "NavigationDuplicated" || error.message.includes("Avoided redundant navigation to current location") || error.message.includes("Navigation cancelled from")) {
                    resolve(this.currentRoute);
                } else {
                    reject(error);
                }
            }
        );
    });
};

export default router;
