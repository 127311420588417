/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Information Line': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2c4.41 0 8-3.59 8-8 0-4.42-3.59-8-8-8-4.42 0-8 3.58-8 8 0 4.41 3.58 8 8 8zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z" _fill="#000" fill-rule="nonzero"/>'
  }
})
