/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vip-crown-2-fill': {
    width: 1,
    height: 1,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="currentColor" d="M2.8 5.2L7 8l4.186-5.86a1 1 0 011.628 0L17 8l4.2-2.8a1 1 0 011.547.95l-1.643 13.967a1 1 0 01-.993.883H3.889a1 1 0 01-.993-.883L1.253 6.149A1 1 0 012.8 5.2zM12 15a2 2 0 100-4 2 2 0 000 4z"/>'
  }
})
