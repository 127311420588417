<template>
    <div class="overflow-hidden">
        <div class="flex flex-row items-center justify-between cursor-pointer" @click="toggle">
            <div :class="{ 'flex items-center pl-5': icon }">
                <icon v-if="icon" class="!fill-brand" width="17px" height="17px" :name="icon" />
                <slot name="header"></slot>
            </div>
            <icon class="mr-5 transition-all duration-200 ease-in-out origin-center" :class="{ ...iconclasses, 'rotate-45': height != '0px'}" width="28px" height="28px" name="Add Fill" />
        </div>
        <div class="transition-all ease-in-out duration-400" id="test" ref="test" :style="{maxHeight: height}">
            <slot name="body"></slot>
        </div>

    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import styled from 'vue-styled-components'
import '@/icons/Add Fill'
import '@/icons/Save Outline'
import '@/icons/Pause Circle'
import '@/icons/Close Circle'
import '@/icons/Save 2 Fill'
import '@/icons/Plus Circle'
import '@/icons/Upload Cloud Fill'
import '@/icons/Crop'
import '@/icons/Edit'
import '@/icons/Chevron Right'
import '@/icons/Chevron Left'
import '@/icons/Heart Mini'
import '@/icons/Star S Fill'
import '@/icons/Star S Line'
import '@/icons/Time Fill'
import '@/icons/Chat Fill'
import '@/icons/Checkbox Circle Fill'
import '@/icons/Mail Fill'
import '@/icons/List Check'
import '@/icons/Arrow Drop Up'


interface Data {
    height: string | null,
    defaultHeight: string | null
}

export default Vue.extend({
    props: {
        label: String,
        icon: String,
        flex: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        iconClass: {
            type: String,
            default: ''
        },
    },

    data: (): Data => ({
        defaultHeight: null,
        height: null
    }),

    mounted() {
        this.defaultHeight = (this.$refs.test as HTMLElement).clientHeight + 'px'
        this.height = '0px'
    },

    methods: {
        toggle(): void {
            this.height = this.height === this.defaultHeight ? '0px' : this.defaultHeight
        }
    },

    computed: {
        iconclasses(): any {
            const output: any = {}
            output[this.iconClass] = true
            return output
        }
    }
})
</script>
<style lang="stylus" scoped>
.wrapper
    overflow hidden
    border 1px solid var(--borders)
    cursor pointer
    background-color var(--space)
    margin-bottom 8px
    margin-top 8px

.title
    display flex
    justify-content space-between
    align-items center
    padding 0px
    background-color var(--space)
    font-weight bold
    cursor pointer
    height 48px
    padding 0px 17px 0px 17px
    color var(--text-primary)
    font-size 15px
    letter-spacing -0.33px
    font-weight 600

    div
        display flex
        align-items center

        svg
            margin-right 8px

.label-icon
    transition all 448ms
    transform scaleY(-1)

    svg
        margin-right 0px

    &.swap
        transform scaleY(1)

.content
    max-height 0px
    transition max-height 448ms ease-out

.content-wrapper
    padding 4px 16px 24px 16px

.open
    max-height 256px
    transition max-height 128ms ease-in

+option('flex')
    display flex
    flex-wrap wrap
    justify-content flex-start
    align-items flex-start

.wrapper
    +option('large')
        .label
            height 56px
            font-size 18px
            font-family var(--display)
            font-weight 600
</style>