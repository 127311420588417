/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Arrow Forward': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 13h11.86l-3.63 4.36a1.001 1.001 0 001.54 1.28l5-6a1.19 1.19 0 00.09-.15c0-.05.05-.08.07-.13A1 1 0 0020 12a1 1 0 00-.07-.36c0-.05-.05-.08-.07-.13a1.19 1.19 0 00-.09-.15l-5-6A1 1 0 0014 5a1 1 0 00-.77 1.64L16.86 11H5a1 1 0 100 2z" _fill="#231F20"/>'
  }
})
