/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Chevron Left': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.36 17a1 1 0 01-.72-.31l-3.86-4a1 1 0 010-1.4l4-4a1.004 1.004 0 011.42 1.42L10.9 12l3.18 3.3a1 1 0 010 1.41 1 1 0 01-.72.29z" _fill="#231F20"/>'
  }
})
