<template>
    <div>
        <transition name="moveUp">
            <div class="InstallPrompt hidden md:flex" v-if="showIosMessage">
                <div>Download this app on your {{ device }} by tapping
                    <icon class="share-outline" name="share-outline" width="20px" height="20px" /> followed by <span>Add to Home Screen
                        <icon width="16px" height="16px" name="add-square" />
                    </span>
                </div>
                <button @click="hide" class="InstallPrompt__btn">
                    <icon name="Close" />
                </button>
            </div>
        </transition>

        <div ref="btn" class="AndroidPrompt hidden md:flex" v-if="showPromptMessage">
            <button class="AndroidPrompt__hide" @click="hideAndroidPrompt">
                <icon name="Close" />
            </button>
            <img class="AndroidPrompt__favicon" :src="favicon" />
            <div class="AndroidPrompt__text">
                <strong>Sneller naar de app?</strong>
                Download onze app.
            </div>
            <styled-button background class="AndroidPrompt__download" @click="handle">Download</styled-button>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import '@/icons/Close'

interface Data {
    showIosMessage: boolean,
    showPromptMessage: boolean,
    prompt: any,
    device: string,
    favicon: any
}

declare var data: any

export default Vue.extend({
    data: (): Data => ({
        showIosMessage: false,
        showPromptMessage: false,
        prompt: null,
        device: '',
        favicon: data.favicon || ''
    }),

    mounted(): void {
        const android = localStorage.getItem('showPromptMessage')

        if (android !== null) {
            this.showPromptMessage = false
            return;
        }

        window.addEventListener('beforeinstallprompt', (e: any) => {
            const addBtn = <any>this.$refs.btn

            e.preventDefault();
            this.prompt = <any>e;
            this.showPromptMessage = true
        });


        const userAgent = window.navigator.userAgent.toLowerCase();

        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();

            return /iphone|ipad|ipod/.test(userAgent);
        }

        const isInStandaloneMode = () => ('standalone' in window.navigator) && ((window.navigator as any).standalone);

        if (isIos() && !isInStandaloneMode()) {
            if (/iphone/.test(userAgent)) {
                this.device = 'iPhone'
            } else if (/ipad/.test(userAgent)) {
                this.device = 'iPad'
            } else {
                this.device = 'device'
            }

            const choice = localStorage.getItem('showIosMessage')

            if (choice !== null) {
                this.showIosMessage = false
                return;
            }

            this.showIosMessage = true
        }
    },

    methods: {
        hide(): void {
            this.showIosMessage = false
            localStorage.setItem('showIosMessage', 'false')
        },

        hideAndroidPrompt(): void {
            this.showPromptMessage = false
            localStorage.setItem('showPromptMessage', 'false')
        },

        handle(): void {
            this.hideAndroidPrompt();
            this.prompt.prompt();

            this.prompt.userChoice.then((choiceResult: any) => {
                this.prompt = null;
            });
        }
    }
})
</script>

<style lang="stylus" scoped>
.InstallPrompt
    position fixed
    left 24px
    width calc(100% - 48px)
    background black
    color white
    z-index 999
    font-size 14px
    font-weight 500
    padding 8px 16px
    line-height 2
    text-align center
    border-radius 8px
    border-top-right-radius 0
    bottom 24px
    padding-bottom 16px

    &:after
        display block
        position absolute
        height 0
        width 0
        top 100%
        margin-left -12px
        left 50%
        content ''
        border-left 12px solid transparent
        border-right 12px solid transparent
        border-top 12px solid black

    svg
        position relative
        color lighten(blue, 30)
        top -2px

    span
        display inline-block
        background #666
        padding 0 8px
        border-radius 4px

        svg
            position relative
            top -1px

.InstallPrompt__btn
    background black
    position absolute
    border-top-right-radius 8px
    border-top-left-radius 8px
    bottom 100%
    width 48px
    height 32px
    line-height 38px
    right 0

.AndroidPrompt
    background #f2f2f2
    display flex
    align-items center
    height 64px
    border-bottom 1px solid darken(#f2f2f2, 5)

.AndroidPrompt__hide
    width 40px
    height 56px
    color #444

.AndroidPrompt__favicon
    width 40px
    height 40px
    background-color var(--space)
    border-radius 4px
    box-shadow 0 0 2px rgba(black, 0.2)
    margin-right 12px

.AndroidPrompt__text
    font-size 13px
    line-height 1.2
    flex 1

    strong
        display block

.AndroidPrompt__download
    margin-right 16px
    padding 4px 8px
    font-weight 600
    font-size 14px
    border-radius 4px
    letter-spacing 0.02em
    text-transform uppercase
    color white

.moveUp-enter-active, .moveUp-leave-active
    transition transform 0.5s, opacity 0.5s

.moveUp-enter, .moveUp-leave-to
    transform translateY(100%)
    opacity 0
</style>
