/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Add Fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"/>'
  }
})
