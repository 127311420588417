<template>
    <div class="block rounded mb-4 mt-4 bg-red-400 shadow" v-if="error">
        <div class="flex items-center p-3 pr-5">
            <div class="flex items-center justify-center w-8 h-8 rounded-full border border-white border-opacity-40 mr-4 text-white" @click="error = false">
                <span class="relative flex w-3 h-3 ">
                    <span class="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-white"></span>
                    <span class="relative inline-flex w-3 h-3 rounded-full bg-white"></span>
                </span>
            </div>

            <div class="border-l pl-4 py-1 border-white border-opacity-40">
                <h3 class="font-display text-base leading-4 font-bold tracking-tight text-white">{{ error }}</h3>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import '@/icons/Error Warning'
import Heading from './Elements/Heading.vue'
export default Vue.extend({
    components: { Heading },
    props: {
        error: String
    },
    // data() {
    //     return {
    //         elementVisible: true
    //     }
    // },

    // created() {
    //     setTimeout(() => this.elementVisible = false, 1000)
    // }
})
</script>
