/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Crop': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#231F20" d="M21 16h-3V8.56A2.56 2.56 0 0015.44 6H8V3a1 1 0 10-2 0v3H3a1 1 0 100 2h3v7.44A2.56 2.56 0 008.56 18H16v3a1 1 0 102 0v-3h3a1 1 0 100-2zM8.56 16a.56.56 0 01-.56-.56V8h7.44c.31 0 .56.25.56.56V16z"/>'
  }
})
