<template>
    <v-icon :url="url" />
</template>

<script lang="ts">
import Vue from 'vue'
import styled from 'vue-styled-components';

const VIcon = styled('div', { url: String })`
  background-image: url(${(props: any) => props.url});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export default Vue.extend({
  components: {
    VIcon
  },

  props: {
    icon: String,
    color: String
  },

  computed: {
    url(): string {
      return this.color ? `${this.icon}?color=${this.color.replace('#', '')}` : this.icon
    }
  }
})
</script>