import styled from 'vue-styled-components';
import Color from 'color'

interface Props extends Theme {
    background: boolean;
    backgrounddark: boolean;
    'background:hover': boolean;
    'backgroundalpha:hover': boolean;
    'color:hover': boolean;
    'border:hover': boolean;
    'borderalpha:hover': boolean,
    'dim:hover': boolean,
    color: boolean;
    item: boolean;
    text: boolean;
    rounded: boolean;
    border: Boolean;
    active: boolean;
    backgroundalpha: Boolean;
    borderalpha: Boolean;
    dim: Boolean;
    dark: Boolean;
    buttonlarge: Boolean;
    buttonsmall: Boolean;
}

export default styled('div', {
    background: Boolean,
    backgrounddark: Boolean,
    'background:hover': Boolean,
    'backgroundalpha:hover': Boolean,
    'color:hover': Boolean,
    'border:hover': Boolean,
    'borderalpha:hover': Boolean,
    'dim:hover': Boolean,
    color: Boolean,
    item: Boolean,
    text: Boolean,
    rounded: Boolean,
    border: Boolean,
    active: Boolean,
    backgroundalpha: Boolean,
    borderalpha: Boolean,
    dim: Boolean,
    dark: Boolean,
    buttonlarge: Boolean,
    buttonsmall: Boolean


})`
    ${(props: Props) => props['backgroundalpha'] ? 'background: ' + Color(props.theme.primary).alpha(0.04) + ';' : ''}
    ${(props: Props) => props['borderalpha'] ? 'border-color: ' + Color(props.theme.primary).alpha(0.1) + '!important ;' : ''}
    ${(props: Props) => props.background ? 'background: ' + props.theme.primary + ';' : ''}
    ${(props: Props) => props.backgrounddark ? 'background: ' + props.theme.primary + ';' + 'filter: ' + 'brightness(40%)' + '!important ;' + 'cursor: pointer;' + 'transition: all 448ms;' : ''}
    ${(props: Props) => props.color ? 'color: ' + props.theme.primary + ';' : ''}
    ${(props: Props) => props.text ? 'color: ' + props.theme.primary + ';' : ''}
    ${(props: Props) => props.rounded ? 'border-radius: ' + props.theme.radius + ';' : ''}
    ${(props: Props) => props.active ? 'background: ' + props.theme.primary + '; color: white;' : ''}
    ${(props: Props) => props.border ? 'border-color: ' + props.theme.primary + '!important ;' : ''}
    ${(props: Props) => props.buttonlarge ? 'background:' + 'linear-gradient(' + Color(props.theme.primary).alpha(1) + ',' + Color(props.theme.primary).alpha(1) + '),' + 'linear-gradient(black, black);' + ';' + 'padding: 16px 72px 16px 72px;' + 'color: white;' + 'font-weight: 700;' + 'letter-spacing: -0.8px;' + 'font-size: 18px;' + 'border-radius: ' + props.theme.radius + ';' + 'cursor: pointer;' + 'transition: all 448ms;' : ''}
    ${(props: Props) => props.buttonsmall ? 'background:' + 'linear-gradient(' + Color(props.theme.primary).alpha(1) + ',' + Color(props.theme.primary).alpha(1) + '),' + 'linear-gradient(black, black);' + ';' + 'padding: 8px 32px 8px 32px;' + 'color: white;' + 'font-weight: 700;' + 'letter-spacing: -0.8px;' + 'font-size: 15px;' + 'border-radius: ' + props.theme.radius + ';' + 'cursor: pointer;' + 'transition: all 448ms;' : ''}

    &:hover {
        ${(props: Props) => props['background:hover'] ? 'background: ' + props.theme.primary + '!important; cursor: pointer; color: white; transition: all 128ms;' : ''}
        ${(props: Props) => props['backgroundalpha:hover'] ? 'background: ' + Color(props.theme.primary).alpha(0.04) + '; cursor: pointer; transition: all 128ms;' : ''}
        ${(props: Props) => props['color:hover'] ? 'color: ' + props.theme.primary + '!important; cursor: pointer; transition: all 128ms;' : ''}
        ${(props: Props) => props['border:hover'] ? 'border-color: ' + Color(props.theme.primary).alpha(0.8) + '!important; cursor: pointer; transition: all 128ms;' : ''}
        ${(props: Props) => props['borderalpha:hover'] ? 'border-color: ' + Color(props.theme.primary).alpha(0.1) + '!important ;' : ''}
        ${(props: Props) => props['dim:hover'] ? 'background:' + 'linear-gradient(' + Color(props.theme.primary).alpha(1) + ',' + Color(props.theme.primary).alpha(0.9) + '),' + 'linear-gradient(black, black);' + 'transition: all 448ms;' : ''}
        ${(props: Props) => props.dim ? 'filter: ' + 'brightness(80%)' + '!important ;' + 'cursor: pointer;' + 'transition: all 448ms;' : ''}
    }

    &:disabled {
        background: var(--space-100) !important;
    }
`;
