/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Close Fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 10.586l4.95-4.95 1.41 1.41-4.95 4.95 4.95 4.95-1.42 1.41-4.95-4.95-4.95 4.95-1.42-1.42 4.95-4.95-4.95-4.95 1.41-1.42z" _fill="#000" fill-rule="nonzero"/>'
  }
})
