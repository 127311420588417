import api from "!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-21.use[1]!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-21.use[2]!../../../node_modules/stylus-loader/index.js!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./Shelf.vue?vue&type=style&index=0&id=929a0efc&prod&lang=stylus&scoped=true";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};