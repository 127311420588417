/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Mail Fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 3h18-.01c.55-.01 1 .44 1 .99v16c0 .55-.45 1-1 1H2.98c-.56-.01-1-.45-1-1.01v-16a.99.99 0 01.99-1.01L3 3zm9.06 8.683l-6.42-5.45-1.295 1.52 7.72 6.55 7.58-6.56-1.31-1.513-6.285 5.43.01.023z" _fill="#000" fill-rule="nonzero"/>'
  }
})
