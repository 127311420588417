/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Chevron Right': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.5 17a1 1 0 01-.71-1.71L13.1 12 9.92 8.69a1 1 0 111.42-1.41l3.86 4a1 1 0 010 1.4l-4 4a1 1 0 01-.7.32z" _fill="#231F20"/>'
  }
})
