/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Arrow Drop Down Line': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15l-4.243-4.243 1.41-1.42 2.82 2.82 2.82-2.83 1.41 1.41z" _fill="#000" fill-rule="nonzero"/>'
  }
})
