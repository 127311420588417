var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h1',{staticClass:"title",class:{
    '--center': _vm.center,
    '--left': _vm.left,
    '--primary': _vm.primary,
    '--sm': _vm.sm,
    '--md': _vm.md,
    '--lg': _vm.lg,
    '--xl': _vm.xl,
    '--xxl': _vm.xxl,
    '--mt_sm': _vm.mt_sm,
    '--mt_md': _vm.mt_md,
    '--mt_lg': _vm.mt_lg,
    '--mt_xl': _vm.mt_xl,
    '--ml_sm': _vm.ml_sm,
    '--ml_md': _vm.ml_md,
    '--ml_lg': _vm.ml_lg,
    '--ml_xl': _vm.ml_xl,
    '--mr_sm': _vm.mr_sm,
    '--mr_md': _vm.mr_md,
    '--mr_lg': _vm.mr_lg,
    '--mr_xl': _vm.mr_xl,
    '--mb_sm': _vm.mb_sm,
    '--mb_md': _vm.mb_md,
    '--mb_lg': _vm.mb_lg,
    '--mb_xl': _vm.mb_xl,
    '--display': _vm.display,
    '--ellipsis': _vm.ellipsis,
    }},[_vm._v("\n    "+_vm._s(_vm.label)+"\n    "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }