/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Globe': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.34v-.001c-.99-2.1-1.56-4.36-1.69-6.67H4.05a7.991 7.991 0 005.648 6.66l.012.011zm.32-6.67c.15 2.439.84 4.73 1.97 6.75v-.001c1.15-2.08 1.82-4.39 1.97-6.76h-3.94v.011zm9.908 0H15.97c-.13 2.31-.7 4.57-1.69 6.66a8.007 8.007 0 005.64-6.67l.018.01zm-15.876-2H8.02c.12-2.32.69-4.58 1.68-6.67-3.04.9-5.26 3.52-5.65 6.66l.012.01zm5.96 0h3.93-.001c-.15-2.37-.82-4.68-1.97-6.76v-.001a15.7 15.7 0 00-1.97 6.75l.011.011zm4.259-6.67c.98 2.09 1.55 4.35 1.68 6.66h3.965a8.02 8.02 0 00-5.65-6.67l.005.01z" _fill="#000" fill-rule="nonzero"/>'
  }
})
