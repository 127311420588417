<template>
    <div class="relative flex flex-col justify-center h-full overflow-hidden bg-neutral-50" :class="{ '!bg-white': $store.getters.isOne2Seat,  }">
        <div class="z-10 flex items-start h-full overflow-hidden p-gutter" v-if="$store.getters.isOne2Seat">
            <figure class="absolute inset-0 flex items-center justify-end"><img src="https://uploads-ssl.webflow.com/5f99a9af7f5b9413ae46e271/5f9aa469889671afb8833aab_img-usp-12.jpg" alt=""></figure>
        </div>

        <template v-else>
            <figure class="absolute inset-0 image-cover" v-if="$store.state.domain.media.login" v-html="$store.state.domain.media.login.responsive.large"></figure>
        </template>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
})
</script>
