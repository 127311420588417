/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Arrow Drop Up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 11.828l-2.83 2.82-1.415-1.42 4.24-4.243 4.24 4.243-1.42 1.41-2.83-2.83z" _fill="#000" fill-rule="nonzero"/>'
  }
})
