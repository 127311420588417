<template>
    <dl class="grid grid-cols-8 items-center justify-between px-4 h-14 group bg-white" :class="{ selected: option.quantity, '--borderless': borderless }">
        <dt class="flex items-center gap-2 col-span-6">
            <figure class="relative square-16 border-2 border-neutral-200 bg-white duration-150 rounded-double" :class="{ '!bg-brand !border-brand': option.quantity }">
                <icon name="ri:check-fill" class="absolute duration-300 animation-delay-300 scale-0 square-12 !fill-white" :class="{ 'scale-100': option.quantity }" />
            </figure>
            <h4 class="text-sm font-semibold leading-none tracking-tight">{{ option.name }}</h4>
        </dt>
        <dd class="col-span-2 button gap-1" :class="{ 'button-primary hover:text-white hover:border-brand': option.quantity }">
            <span v-html="option.quantity ? '+' : '+'" />
            <span v-if="($store.state.company.hide_free_prices && option.price) || !$store.state.company.hide_free_prices">{{ option.price | eur }}</span>
        </dd>
    </dl>
</template>

<script lang="ts">
import Vue from "vue";
import "@/icons/ri:add-circle-fill";
import "@/icons/ri:checkbox-blank-circle-line";
import "@/icons/ri:check-fill";

export default Vue.extend({
    props: {
        option: Object as () => ProductOption,
        borderless: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
