import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import calendar from 'dayjs/plugin/calendar'
import duration from 'dayjs/plugin/duration'
import store from '@/store/store'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import isoWeek from 'dayjs/plugin/isoWeek'

import 'dayjs/locale/nl'

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(calendar)
dayjs.extend(duration)
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(isoWeek)

dayjs.locale(store.state.locale)

export default dayjs

