<template>
    <h1 class="title"
        :class="{
        '--center': center,
        '--left': left,
        '--primary': primary,
        '--sm': sm,
        '--md': md,
        '--lg': lg,
        '--xl': xl,
        '--xxl': xxl,
        '--mt_sm': mt_sm,
        '--mt_md': mt_md,
        '--mt_lg': mt_lg,
        '--mt_xl': mt_xl,
        '--ml_sm': ml_sm,
        '--ml_md': ml_md,
        '--ml_lg': ml_lg,
        '--ml_xl': ml_xl,
        '--mr_sm': mr_sm,
        '--mr_md': mr_md,
        '--mr_lg': mr_lg,
        '--mr_xl': mr_xl,
        '--mb_sm': mb_sm,
        '--mb_md': mb_md,
        '--mb_lg': mb_lg,
        '--mb_xl': mb_xl,
        '--display': display,
        '--ellipsis': ellipsis,
        }">
        {{ label }}
        <slot />
    </h1>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
    props: {
        label: String,
        center: { type: Boolean, default: false },
        left: { type: Boolean, default: false },
        primary: { type: Boolean, default: false },
        sm: { type: Boolean, default: false },
        md: { type: Boolean, default: false },
        lg: { type: Boolean, default: false },
        xl: { type: Boolean, default: false },
        xxl: { type: Boolean, default: false },
        mt_sm: { type: Boolean, default: false },
        mt_md: { type: Boolean, default: false },
        mt_lg: { type: Boolean, default: false },
        mt_xl: { type: Boolean, default: false },
        ml_sm: { type: Boolean, default: false },
        ml_md: { type: Boolean, default: false },
        ml_lg: { type: Boolean, default: false },
        ml_xl: { type: Boolean, default: false },
        mr_sm: { type: Boolean, default: false },
        mr_md: { type: Boolean, default: false },
        mr_lg: { type: Boolean, default: false },
        mr_xl: { type: Boolean, default: false },
        mb_sm: { type: Boolean, default: false },
        mb_md: { type: Boolean, default: false },
        mb_lg: { type: Boolean, default: false },
        mb_xl: { type: Boolean, default: false },
        display: { type: Boolean, default: false },
        ellipsis: { type: Boolean, default: false },
    }
})
</script>

<style lang="stylus" scoped>
.title
    --font-size 1rem
    color var(--text-primary)
    font-size var(--font-size)
    letter-spacing -0.6px
    font-weight 600

    // Sizes
    +option('sm')
        --font-size 0.875rem
        font-size var(--font-size)
        line-height var(--font-size)
        letter-spacing -0.3px

    +option('md')
        --font-size 1rem
        font-size var(--font-size)
        line-height var(--font-size)
        letter-spacing -0.6px

    +option('lg')
        --font-size 1.1875rem
        font-size var(--font-size)
        line-height var(--font-size)
        letter-spacing -0.8px

    +option('xl')
        --font-size 1.5rem
        font-size var(--font-size)
        line-height var(--font-size)
        letter-spacing -1.1px

    +option('xxl')
        --font-size 2.25rem
        font-size var(--font-size)
        line-height var(--font-size)
        letter-spacing -1.6px

    // Spacing
    +option('mt_sm')
        margin-top 4px

    +option('mt_md')
        margin-top 8px

    +option('mt_lg')
        margin-top 16px

    +option('mt_xl')
        margin-top 24px

    +option('ml_sm')
        margin-left 4px

    +option('ml_md')
        margin-left 8px

    +option('ml_lg')
        margin-left 16px

    +option('ml_xl')
        margin-left 24px

    +option('mr_sm')
        margin-right 4px

    +option('mr_md')
        margin-right 8px

    +option('mr_lg')
        margin-right 16px

    +option('mr_xl')
        margin-right 24px

    +option('mb_sm')
        margin-bottom 4px

    +option('mb_md')
        margin-bottom 8px

    +option('mb_lg')
        margin-bottom 16px

    +option('mb_xl')
        margin-bottom 24px

    // Layout
    +option('center')
        text-align center

    +option('left')
        text-align left

    // Style
    +option('display')
        font-family var(--display)
        font-weight 700
        font-size calc(var(--font-size) * 1.1)

    +option('ellipsis')
        overflow hidden
        max-width 100%
        white-space nowrap
        text-overflow ellipsis

    +option('primary')
        color var(--brand)
</style>
