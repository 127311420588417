/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Chat Fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10 3h4-.01c4.41-.01 8 3.58 8 7.99s-3.59 8-8 8v3.5c-5-2-12-5-12-11.5-.01-4.42 3.58-8.01 7.99-8.01L10 3z" _fill="#000" fill-rule="nonzero"/>'
  }
})
