<template>
    <component :is="component" :to="to">
        <article data-servicecard class="flex flex-col gap-3 flex-grow group relative rounded-double cursor-pointer transition-all duration-200 active:scale-[.98]">
            <!-- aspect-[65/79] -->
            <figure class="relative overflow-hidden bg-zinc-50 rounded-double aspect-1 md:aspect-[1.6] hover:scale-[1.02] transition-all duration-300" :class="{ 'aspect-2 xl:aspect-[1.8] md:!aspect-[1.4] order-2': featured || challenge, '!bg-transparent aspect-1': background }">
                <figure class="absolute inset-0 z-10 border rounded-double mix-blend-multiply border-primary opacity-5"></figure>
                <picture v-if="responsive" class="bg-white absolute inset-0 overflow-hidden rounded-double [&>img]:w-full [&>img]:object-cover [&>img]:h-full" v-html="responsive"></picture>
                <img v-else class="bg-white absolute inset-0 overflow-hidden rounded-double object-cover w-full h-full" :src="image" alt="" />
                <dl class="relative flex justify-end p-2 space-x-1" :class="{ '!p-gutter !justify-start': featured || challenge }">
                    <slot name="labels"></slot>
                    <dt class="label-primary" v-if="rating > 3.5">
                        <icon class="!fill-brand !square-14 -ml-1 mr-1" name="Star S Fill" />
                        {{ rating }}
                    </dt>
                    <dt v-if="sponsored" class="label-primary">
                        <icon class="!fill-brand !square-14 -ml-1 mr-1" name="Copper Coin Fill" />
                        <span>{{ $t("Sponsored") }}</span>
                    </dt>
                    <dt v-for="t in freeTags" :key="t" class="label-primary">
                        {{ t }}
                    </dt>
                    <dt v-if="featured" class="label-primary">
                        <icon class="!fill-brand !square-14 -ml-1 mr-1" name="flashlight-fill" />
                        <span>{{ $t("Featured") }}</span>
                    </dt>
                    <dt v-if="challenge" class="label-primary">
                        <icon class="!fill-brand !square-14 -ml-1 mr-1" name="vip-crown-2-fill" />
                        <span>{{ $t("Challenge") }}</span>
                    </dt>
                    <dt v-if="popular" class="label-primary">
                        <icon class="!fill-brand !square-14 -ml-1 mr-1" name="flashlight-fill" />
                        <span>{{ $t("Popular") }}</span>
                    </dt>
                    <dt v-if="points" class="label-primary bg-brand text-white pl-2">
                        +{{ points }}
                        <icon class="!fill-white !square-14 -mr-2 ml-1" name="Coin" />
                    </dt>
                </dl>
                <figure v-if="featured" class="absolute inset-0 bg-gradient-100 rounded-double"></figure>
                <dl v-if="featured" class="absolute inset-gutter text-white top-auto z-20 flex flex-col items-start justify-between h-auto">
                    <dd class="text-base line-clamp-1">{{ catchfrase }}</dd>
                    <dt class="text-xl font-bold tracking-[-0.1px] font-display md:text-xl md:leading-5">{{ title }}</dt>
                    <button class="button rounded-full mt-4 border-white hover:!border-white/60 hover:!bg-white/60">Learn more</button>
                </dl>
            </figure>
            <dl v-if="!featured" class="flex flex-col justify-between h-auto">
                <dt class="text-lg font-bold leading-5 line-clamp-1 tracking-[-0.1px] font-display" :class="{ '!text-white': background, 'text-2xl font-bold leading-6 tracking-[-0.4px] font-display md:text-xl md:leading-5': featured }">{{ title }}</dt>
                <dd class="text-sm tracking-[-0.1px] line-clamp-1 text-secondary" :class="{ 'text-base font-medium text-secondary': featured }">{{ catchfrase }}</dd>
            </dl>
        </article>
    </component>
</template>

<script lang="ts">
import Vue from "vue";
import "@/icons/Star S Fill";
import "@/icons/Copper Coin Fill";
import "@/icons/flashlight-fill";
import "@/icons/vip-crown-2-fill";
import "@/icons/vip-crown-2-line";
import "@/icons/Coin";

export default Vue.extend({
    components: {},
    props: {
        image: String,
        responsive: String,
        title: String,
        rating: String,
        to: Object,
        sponsored: String,
        catchfrase: String,
        points: String,
        tags: {
            type: Array,
            default: () => [],
        },
        portrait: {
            type: Boolean,
            default: false,
        },
        background: {
            type: Boolean,
            default: false,
        },
        featured: {
            type: Boolean,
            default: false,
        },
        challenge: {
            type: Boolean,
            default: false,
        },
        popular: {
            type: Boolean,
            default: false,
        },
        square: {
            type: Boolean,
            default: false,
        },
        shelf: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        freeTags(): any {
            return this.tags.filter((t: any) => t.visible && (t.name.includes("free") || t.name.includes("Free") || t.name.includes("gratis") || t.name.includes("Gratis"))).map((t: any) => t.name);
        },

        component(): any {
            if (!this.to) {
                return "div";
            }

            return "router-link";
        },
    },
});
</script>
