/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Alert Circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#231F20" d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 15a1 1 0 111-1 1 1 0 01-1 1zm1-4a1 1 0 11-2 0V8a1 1 0 112 0z"/>'
  }
})
