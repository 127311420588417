/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Arrow Left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.82 11h12.172v2H7.82l5.36 5.36-1.42 1.41-7.78-7.78 7.77-7.78 1.41 1.41z" _fill="#000" fill-rule="nonzero"/>'
  }
})
