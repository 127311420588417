<template>
    <section class="w-full">
        <div :class="{ 'container md:overflow-hidden': !noContainer }">
            <header class="flex items-center justify-between" :class="{ ...headerclasses }">
                <dl v-if="title" class="mb-6 md:mb-4">
                    <dt class="text-2xl font-bold leading-6 font-display md:text-xl md:leading-5" v-if="title">{{ title }}<slot name="title" /></dt>
                    <dd class="text-neutral-400 font-medium" v-if="subtitle">{{ subtitle }}<slot name="subtitle" /></dd>
                </dl>

                <nav class="flex items-center justify-end md:hidden gap-1">
                    <slot class="text-base font-medium text-brand" name="nav"></slot>
                    <a class="shelf-link" v-if="link" :to="link"></a>

                    <figure class="rounded-full square-32" ref="prev" :class="{ 'button-glass': glass, button: !glass }">
                        <icon class="square-24" name="Arrow Drop Left Line" />
                    </figure>
                    <figure class="rounded-full square-32" ref="next" :class="{ 'button-glass': glass, button: !glass }">
                        <icon class="square-24" name="Arrow Drop Right Line" />
                    </figure>
                </nav>
            </header>

            <main ref="shelf">
                <slot />
            </main>

            <slot name="empty" />
        </div>
    </section>
</template>

<script lang="ts">
import Vue from "vue";
import merge from "lodash/merge";
import { tns, TinySliderInstance, TinySliderSettings, SilderEvent } from "tiny-slider/src/tiny-slider.js";
import "@/icons/Arrow Back";
import "@/icons/Arrow Forward";
import "@/icons/Arrow Drop Right Line";
import "@/icons/Arrow Drop Left Line";

interface Data {
    instance: TinySliderInstance | null;
    current: number | null;
    count: number | null;
}

export default Vue.extend({
    props: {
        title: { default: "" },
        subtitle: { default: "" },
        link: String,
        small: Number,
        medium: Number,
        large: Number,
        options: {
            type: Object as () => TinySliderSettings,
        },
        noContainer: {
            type: Boolean,
            default: false,
        },
        glass: {
            type: Boolean,
            default: false,
        },
        speed: {
            type: Number,
            default: 400,
        },
        autoplay: {
            type: Boolean,
            default: false,
        },
        loop: {
            type: Boolean,
            default: false,
        },
        navTop: {
            type: Boolean,
            default: false,
        },
        center: {
            type: Number,
            default: null,
        },
        headerClass: {
            type: String,
            default: "",
        },
    },

    watch: {
        center(): void {
            this.instance!.goTo(this.center);
        },
    },

    data(): Data {
        return {
            instance: null,
            current: null,
            count: null,
        };
    },

    mounted(): void {
        this.instance = tns(
            merge(
                {
                    container: this.$refs.shelf,
                    swipeAngle: 45,
                    preventScrollOnTouch: "auto",
                    loop: this.loop,
                    // slideBy: 'page',
                    mouseDrag: true,
                    controls: true,
                    nav: false,
                    autoplay: this.autoplay,
                    speed: this.speed,
                    items: this.small,
                    edgePadding: 0,
                    gutter: 20,
                    responsive: {
                        767: {
                            gutter: 20,
                            items: this.small,
                            edgePadding: 0,
                        },
                        768: {
                            gutter: 20,
                            items: this.medium - 1,
                            edgePadding: 0,
                        },
                        992: {
                            gutter: 20,
                            items: this.medium,
                            edgePadding: 0,
                        },
                        1279: {
                            gutter: 20,
                            items: this.large,
                            edgePadding: 0,
                        },
                    },
                    prevButton: this.$refs.prev,
                    nextButton: this.$refs.next,
                },
                this.options
            )
        );

        if (this.instance) {
            this.instance.events.on("transitionEnd", (data) => {
                this.count = data.slideCount;
            });

            this.instance.events.on("indexChanged", (data) => {
                this.current = data.index;
                this.$emit("indexUpdate", data.index);
                this.$emit("slideCountUpdate", data.slideCount);
            });

            this.instance.events.on("indexChanged", (data) => {
                this.current = data.index;
                this.$emit("indexUpdate", data.index);
                this.$emit("slideCountUpdate", data.slideCount);
            });

            this.$emit("indexUpdate", 0);
        }
    },

    methods: {
        refresh() {
            if (this.instance) {
                this.instance.refresh();
            }
        },
    },

    computed: {
        headerclasses(): any {
            const output: any = {};
            output[this.headerClass] = true;
            return output;
        },
    },

    beforeDestroy() {
        setTimeout(() => {
            if (this.instance) {
                this.instance.destroy();
                this.instance = null;
            }
        }, 1000);
    },
});
</script>

<style lang="stylus" scoped>
.group
    [aria-disabled=true]
        opacity 0
        cursor default

[aria-disabled=true]
    opacity 0.5
    cursor default

>>> .tns-visually-hidden
    display none !important

>>> .tns-outer
    padding 16px !important
    margin -16px !important
    user-select none
    overflow hidden

    +below(768px)
        overflow visible

>>> .tns-outer > button
    display none !important

.tns-inner
    //

.tns-nav
    background-color var(--space)
    margin-bottom 1.5rem
    display flex
    justify-content center
    width 100%
    margin-top 1.5rem
    border-radius 4px

    button
        background rgba(24, 24, 40, 0.08)
        margin-right 0px
        width 6px
        height 2px
        border-radius 4px
        flex-grow 1
        margin-right 4px

.tns-nav-active
    background rgba(24, 24, 40, 0.24) !important

.tns-outer [hidden]
    display none !important

.tns-outer [aria-controls], .tns-outer [data-action]
    cursor pointer

.tns-slider
    transition all 0s

.tns-slider > .tns-item
    box-sizing border-box

.tns-horizontal.tns-subpixel
    white-space nowrap

.tns-horizontal.tns-subpixel > .tns-item
    display inline-block
    vertical-align top
    white-space normal

.tns-horizontal.tns-no-subpixel:after
    content ''
    display table
    clear both

.tns-horizontal.tns-no-subpixel > .tns-item
    float left

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item
    margin-right -100%

.tns-no-calc
    position relative
    left 0

.tns-gallery
    position relative
    left 0
    min-height 1px

.tns-gallery > .tns-item
    position absolute
    left -100%
    transition transform 0s, opacity 0s

.tns-gallery > .tns-slide-active
    position relative
    left auto !important
    transition all 0.25s

.tns-autowidth
    display inline-block

.tns-lazy-img
    transition opacity 0.6s
    opacity 0.6

.tns-lazy-img.tns-complete
    opacity 1

.tns-ah
    transition height 0s

.tns-ovh
    overflow visible

    +above(768px)
        overflow visible

    +above(1096px)
        overflow visible

.tns-visually-hidden
    position absolute
    left -10000em

.tns-transparent
    opacity 0
    visibility hidden

.tns-fadeIn
    opacity 1
    z-index 0

.tns-normal, .tns-fadeOut
    opacity 0
    z-index -1

.tns-vpfix
    white-space nowrap

.tns-vpfix > div, .tns-vpfix > li
    display inline-block

.tns-t-subp2
    margin 0 auto
    width 310px
    position relative
    height 10px
    overflow hidden

.tns-t-ct
    width 2333.3333333%
    width calc((100% * 70 / 3))
    position absolute
    right 0

.tns-t-ct:after
    content ''
    display table
    clear both

.tns-t-ct > div
    width 1.4285714%
    width calc((100% / 70))
    height 10px
    float left
</style>
