/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Refresh': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm4.82-4.93c2.8-2.66 2.92-7.1.26-9.9a7.017 7.017 0 00-8.06-1.53l.97 1.755c2.53-1.11 5.47.06 6.57 2.59.27.62.41 1.3.41 1.99h-3l2.82 5.07.03.025zm-1.86 1.26l-.98-1.76a5.005 5.005 0 01-6.58-2.6c-.28-.63-.42-1.31-.42-2h3L7.16 6.89c-2.81 2.65-2.93 7.09-.27 9.89a7.012 7.012 0 008.05 1.52l.02.03z" _fill="#000" fill-rule="nonzero"/>'
  }
})
