import '@babel/polyfill';
import 'url-search-params-polyfill';


import Vue from 'vue'
import Icon from 'vue-svgicon'
import store from './store/store'
import { router } from './tools/router'
import { i18n } from './tools/i18n'
import VueOffline from 'vue-offline'
import vueDebounce from 'vue-debounce'
import '../css/app.css'


import App from './App.vue'
import Async from '@/components/Async'
import Loading from '@/components/Loading.vue'
import ErrorComponent from '@/components/Error.vue'
import Flex from '@/components/Flex.vue'
import Column from '@/components/Column.vue'
import Styled from '@/components/styled/Styled'
import StyledButton from '@/components/styled/StyledButton'
import StyledLink from '@/components/styled/StyledLink'
import PrimaryRouterLink from '@/components/styled/PrimaryRouterLink'
import StyledRouterLink from '@/components/styled/StyledRouterLink'
import FormInput from '@/components/Form/FormInput.vue'
import FormButton from '@/components/Form/FormButton.vue'
import ServiceIcon from '@/components/ServiceIcon.vue'
import * as Sentry from "@sentry/vue";

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: "https://4de2183e5a1c83a271c5ad2be454e5e8@o4506655007965184.ingest.sentry.io/4506711490297856",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 0,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
    });
}

Vue.use(vueDebounce)

Vue.use(VueOffline, {
  mixin: false
})

Vue.filter('eur', function (value: number, noHide?: boolean) {
  if (!noHide && store.state.company?.hide_free_prices && value === 0) {
    return;
  }

  return '€' + ((value / 100).toFixed(2))
})

Vue.filter('ucfirst', function (value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
})

Vue.filter('lowercase', function (value: string) {
  return value.toLowerCase()
})

Vue.component('flex', Flex);
Vue.component('column', Column);
Vue.component('themed', Styled);
Vue.component('styled', Styled);
Vue.component('error', ErrorComponent);
Vue.component('styled-button', StyledButton);
Vue.component('styled-link', StyledLink);
Vue.component('styled-router-link', StyledRouterLink);
Vue.component('primary-router-link', PrimaryRouterLink);
Vue.component('form-input', FormInput);
Vue.component('form-button', FormButton);
Vue.component('service-icon', ServiceIcon);
Vue.component('async', Async);
Vue.component('loading', Loading);


Vue.config.productionTip = false

Vue.prototype.asset = function (path: string) {
  return (process.env.ASSET_PATH as string).replace(/^\/|\/$/g, '') + '/' + path.replace(/^\/|\/$/g, '')
}

Vue.mixin({
  methods: {
    $asset: str => 'test' + str
  }
})


Vue.use(Icon, {
  tagName: 'icon'
})


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

if (process.env.NODE_ENV === 'development' && (module as any).hot) {
  (module as any).hot.accept();
}
