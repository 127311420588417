import Vue from 'vue'
import VueI18n, {DateTimeFormats} from 'vue-i18n'

declare var translations: any;
declare var user: any;
declare var domain: Domain;

Vue.use(VueI18n)

const dateTimeFormats: DateTimeFormats = {
    nl: {
        MMMM: {
            month: 'long'
        },
        DDD: {
            weekday: 'short'
        },
        short: {
            weekday: 'long', month: 'long', day: 'numeric'
        },
        long: {
            weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
        },
        full: {
            weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
        }
    },
    en: {
        MMMM: {
            month: 'long'
        },
        DDD: {
            weekday: 'short'
        },
        short: {
            weekday: 'short', month: 'long', day: 'numeric'
        },
        long: {
            weekday: 'short', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
        },
        full: {
            weekday: 'short', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
        }
    }
}

export const i18n = new VueI18n({
    locale: user ? user.locale : (localStorage.getItem('locale') || domain.language),
    messages: translations,
    dateTimeFormats: dateTimeFormats,
    formatFallbackMessages: true
})
