import Vue from 'vue'
import styled from 'vue-styled-components'
import Router from 'vue-router'

Vue.use(Router)

const RouterLink = Vue.component('RouterLink')

export default styled(RouterLink)`
  color: ${(props: Theme) => props.theme.primary};

  &:hover {
    text-decoration: underline;
  }
`;