<template>
    <button v-if="$store.state.domain.chat" @click="loadChat" :class="{ 'animate-pulse': loading && !loaded}">
        <slot />
    </button>
</template>

<script lang="ts">
import Vue from "vue";
import '@/icons/Loader 3 Fill'
import '@/icons/Questionnaire Fill'

declare var window: any;
declare var Ze: any;
declare var zE: any;

window.loadChat = function (callback: any) {
    if (!!window.Ze) {
        return true;
    }

    let script = document.createElement("script");
    script.src = "https://static.zdassets.com/ekr/snippet.js?key=9bfe58f4-673a-439f-a2c5-bdfee81d0dd1";
    script.id = "ze-snippet"
    script.onload = callback
    document.body.appendChild(script);
};

export default Vue.extend({
    data: () => ({
        loaded: false,
        loading: false
    }),

    methods: {
        chatLoaded(): void {
            zE(() => {
                zE.activate();

                zE('webWidget:on', 'userEvent', (event: any) => {
                    if (event.action === 'Web Widget Opened') {
                        this.loaded = true
                    }
                })
            });
        },

        loadChat(): void {
            this.loading = true

            window.loadChat(this.chatLoaded)
        }
    }
})
</script>