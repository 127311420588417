/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Information Fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11v6h2v-6h-2zm0-4v2h2V7h-2z" _fill="#000" fill-rule="nonzero"/>'
  }
})
