import Vue from 'vue'
import store from '@/store/store'
import axios from '../services/Clients/AxiosClient'
import mapValues from 'lodash/mapValues'

export default Vue.extend({
  props: {
    fetch: String
  },

  data: (): any => ({
    data: null,
    loading: false,
    waiting: false,
    done: false,
    error: null,
    errors: {},
    results: null,
    saved: false
  }),

  mounted() {
    if (this.fetch) {
      this.get(this.fetch)
    }
  },

  methods: {
    reload() {
        this.get(this.fetch)
    },

    get(url: string) {
      this.query('get', url)
    },

    put(url: string, data: { [key: string]: any }) {
      return this.query('put', url, data)
    },

    post(url: string, data: { [key: string]: any }) {
      return this.query('post', url, data)
    },

    patch(url: string, data: { [key: string]: any }) {
      return this.query('patch', url, data)
    },

    remove(url: string, data: { [key: string]: any }) {
      return this.query('remove', url, data)
    },

    query(type: 'get' | 'put' | 'post' | 'patch' | 'remove', url: string, data?: { [key: string]: any }) {
      if (this.loading) {
        return
      }

      // @ts-ignore
      if (type === 'get' && store.state.requests[url]) {
        this.loading = false
        this.waiting = false
        this.done = true

        // @ts-ignore-line
        this.data = store.state.requests[url]

        return
      }

      this.loading = true
      this.done = false
      this.error = null
      this.errors = {}

      setTimeout(() => {
        if (this.loading) {
          this.waiting = true
        }
      }, 250)

        const request = type === 'get'
            ? axios.get(url) // @ts-ignore
            : axios[type](url, data)

        return request
        .then((response: any) => {
          this.data = response.data.data
          this.$emit('success', response)// @ts-ignore

            if (type === 'get') {
                // @ts-ignore-line
                // store.state.requests[url] = response.data.data
            }

            if (type !== 'get') {
                this.saved = true
                setTimeout(() => this.saved = false, 1000)
            }
        }).catch((error: any) => {
          this.error = error.response?.data?.message || error.response?.data?.error

          if (error.response?.data?.errors) {
            this.errors = mapValues(error.response?.data?.errors, (e: string[]) => e[0])
          }

          this.$emit('error', error)
        }).finally(() => {
          this.loading = false
          this.waiting = false
          this.done = true
        })
    },
  },

  render(): any {
    return (this.$scopedSlots as any).default({
      loading: this.loading,
      waiting: this.waiting,
      done: this.done,
      error: this.error,
      errors: this.errors,
      post: this.post,
      put: this.put,
      patch: this.patch,
      data: this.data,
      reload: this.reload,
      saved: this.saved
    })
  }
})
