<template>
    <button type="submit" class="button-primary" :class="{disabled: waiting, saved: saved}" :disabled="loading">
        <template v-if="withIcon">
            <icon v-if="saved" width="17px" height="17px" name="Checkmark" />
            <icon class="rotate" v-else-if="waiting" width="17px" height="17px" name="Loader 3 Fill" />
            <icon v-else width="17px" height="17px" name="Save 2 Fill" />
        </template>

        <slot>
            <span v-if="label">{{ waiting ? $t('Saving {label}, please wait...', {label: lowercase}) : saved ? $t('{label} saved!', {label: capitalize}) : $t('Save {label}', {label: lowercase}) }}</span>
            <span v-else>{{ waiting ? $t('Saving, please wait...', {label: lowercase}) : saved ? $t('Saved!', {label: capitalize}) : $t('Save', {label: lowercase}) }}</span>
        </slot>
    </button>
</template>


<script lang="ts">
import Vue from "vue";
import "@/icons/Checkmark"

export default Vue.extend({
    props: {
        waiting: Boolean,
        loading: Boolean,
        saved: Boolean,
        label: {
            default: ''
        },
        withIcon: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        capitalize(): string {
            return this.label.charAt(0).toUpperCase() + this.lowercase.slice(1);
        },

        lowercase(): string {
            return this.label.toLowerCase();
        },
    }
})
</script>
