/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Email': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#231F20" d="M19 4H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V7a3 3 0 00-3-3zm0 2l-6.5 4.47a1 1 0 01-1 0L5 6z"/>'
  }
})
