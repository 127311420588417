<template>
    <div class="flex w-full m-h-screen">
        <section class="w-1/3 min-w-[620px] overflow-hidden md:w-full md:min-w-screen">
            <div v-if="isOffline" class="fixed inset-0 bottom-auto w-full p-4 text-sm tracking-tight text-center text-white z-60 bg-brand">
                {{ $t("You are currently offline, connect your device to login or register.") }}
            </div>
            <div
                v-if="!!$store.state.newVersion"
                class="fixed inset-0 bottom-auto w-full p-4 text-sm tracking-tight text-center text-white z-60 bg-brand"
                @click="$store.dispatch('updateApplication')"
            >
                <icon class="mr-1 -mt-[2px] rotate text-brand md:mr-0 md:-ml-0 md:mb-1 square-16" name="Refresh" />
                <span class="font-medium leading-5 tracking-tight">{{ $t("New version available, click to update.") }}</span>
            </div>
            <div class="flex flex-col justify-between max-w-2xl min-h-screen mx-auto p-gutter">
                <header class="flex items-center justify-start mt-gutter">
                    <img class="h-14" :src="$store.state.company.logo || require('../../../img/logo-tos.svg')" alt="Logo" />
                </header>

                <main>
                    <slot />
                </main>

                <footer class="relative flex flex-col items-start w-auto">
                    <div class="font-medium tracking-tight text-secondary">
                        {{ $t("Need a hand?") }}
                        <chat-trigger class="font-medium tracking-tight underline text-brand">{{ $t("Talk to support") }}</chat-trigger>
                    </div>

                    <div class="relative mt-4">
                        <button class="button" @click="toggle">
                            <icon class="!square-14 mr-1" name="Globe" />
                            <span>{{ $t("Language") }}</span>
                        </button>

                        <transition name="fromtop">
                            <div v-if="lang" v-on-clickaway="toggle" class="absolute bottom-[48px] border rounded bg-white shadow overflow-hidden">
                                <button class="w-full border-0 border-b rounded-none button" @click="$store.commit('setLocale', 'en')">{{ $t("English") }}</button>
                                <button class="w-full border-0 rounded-none button" @click="$store.commit('setLocale', 'nl')">{{ $t("Dutch") }}</button>
                            </div>
                        </transition>
                    </div>
                </footer>
            </div>
        </section>
        <aside class="flex-grow w-2/3 bg-secondary md:hidden">
            <component :is="component" class="min-h-screen min-w-10" />
        </aside>
    </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import axios from "@/services/Clients/AxiosClient";
import { directive as onClickaway } from "vue-clickaway";
import LoginInformation from "./Parts/LoginInformation.vue";
import RegisterInformation from "./Parts/RegisterInformation.vue";
import "@/icons/Diagonal Arrow Right UP Outline";
import "@/icons/Globe";
import "@/icons/Refresh";
import "@/icons/Arrow Left";
import "@/icons/Information Line";
import { VueOfflineMixin } from "vue-offline";
import ChatTrigger from "../ChatTrigger.vue";

declare var $zopim: any;

export default Vue.extend({
    components: { ChatTrigger },
    directives: {
        onClickaway: onClickaway,
    },
    mixins: [VueOfflineMixin],
    props: {},
    data() {
        return {
            lang: false,
            modal: false,
        };
    },
    created(): void {},
    methods: {
        supportLink(): void {
            const url = this.$store.state.store.support.url;

            if (url) {
                window.open(url);
            }

            if (typeof $zopim === "function") {
                $zopim.livechat.window.show();
            }
        },
        toggle() {
            this.lang = !this.lang;
        },
        toggleModal() {
            this.modal = !this.modal;
        },
    },
    computed: {
        component(): VueConstructor {
            return this.$route.name === "register" || this.$route.name === "login.sso" ? RegisterInformation : LoginInformation;
        },
    },
});
</script>
