import axios, { AxiosResponse } from "axios";
import { i18n } from "@/tools/i18n";
import store from "@/store/store";
import Vue from "vue";

axios.defaults.withCredentials = true;
axios.defaults.params = { lang: i18n.locale };
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.request.use((config: any) => {
    config.errorContext = new Error("Thrown at:");

    return config;
});

axios.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response.data.hasOwnProperty("message")) {
            Vue.notify({
                type: "success",
                text: response.data.message,
                duration: 2500,
            });
        }

        return Promise.resolve(response);
    },
    (error: any) => {
        const originalStackTrace = error.config?.errorContext?.stack;

        if (error.response?.data?.message) {
            Vue.notify({
                type: "error",
                text: error.response?.data?.message,
                duration: 2500,
            });
        }

        if (originalStackTrace) {
            error.stack = `${error.stack}\n${originalStackTrace}`;
        }

        // if (error.response.status === 403 && error.response?.data?.message === 'Your email address is not verified.') {
        //   router.push('/email/verify')
        // }

        return Promise.reject(error);
    }
);

export default axios;
