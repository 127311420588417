import Vue from 'vue'
import axios from '../services/Clients/AxiosClient'
import { Workbox } from 'workbox-window';
import Vuex from 'vuex'
import dayjs from 'dayjs'


Vue.use(Vuex)

declare var data: any;
declare var store: any;
declare var company: any;
declare var domain: any;
declare var user: any;
declare var window: any;
const floatingCartItems: CartItem[] = []

export default new Vuex.Store({
    state: {
        ready: false,
        user: user,
        company: company,
        domain: domain,
        data: data,
        store: store,
        loyaltyProgram: null,
        location: user && user.location ? user.location : null as null | Location,
        waitingLocation: null as number | null,
        videos: [],
        posts: [],
        officePosts: [],
        locations: [],
        categories: [],
        suppliers: [],
        darkMode: localStorage.getItem('theme') === 'darkMode',
        stack: [],
        surveys: [],
        search: {
            search: '',
            filters: []
        },
        wb: null as null | Workbox,
        sw: null as null | ServiceWorkerRegistration,
        newVersion: null as null | ServiceWorker,
        locale: user ? user.locale : localStorage.getItem('locale') || 'nl',
        notice: localStorage.getItem('notice') || true,
        requests: {},
        floatingCartItems
    },

    getters: {
        justRegistered(state): any {
            return state.user && dayjs(state.user.activated_at).add(4, 'weeks') > dayjs()
        },
        businessOrders: (state) => {
            if ( !state.user.companyRoles || !state.user.companyRoles.length ) {
                return 'personal-only'
            }

            if (state.user.companyRoles.find((r: any) => r.invoices === 'business')) {
                return 'business'
            }

            if (state.user.companyRoles.find((r: any) => r.invoices === 'personal')) {
                return 'personal'
            }

            if (state.user.companyRoles.find((r: any) => r.invoices === 'business-only')) {
                return 'business-only'
            }

            if (state.user.companyRoles.find((r: any) => r.invoices === 'personal-only')) {
                return 'personal-only'
            }

            return 'personal-only'
        },
        poNumbers: (state): any => {
            return state.company.po_numbers
        },
        logo: (state): string => {
            if (state.user && state.user.company && state.user.company.logo) {
                return state.user.company.logo
            }

            return '../../img/logo-tos.svg'
        },
        isMobile: (): boolean => {
            return window.innerWidth <= 768;
        },
        isAuthenticated: (state): Boolean => !!state.user,
        isOne2Seat: (state): Boolean => state.store.slug === 'one2seat',
        challengesFeature: (state): Boolean => state.store.features.challenges,
        isGuest: (state): Boolean => !state.user,
        location: (state): any => {
            return state.location
        },
        shopCategories: (state) => {
            return state.categories.filter((c: any) => c.type === 'shop')
        },
        challengeCategories: (state) => {
            return state.categories.filter((c: any) => c.type === 'challenge')
        },
        serviceCategories: (state): Category[] => {
            return state.categories.filter((c: Category) => c.type === 'service')
        },
        favoriteCategories: (state, getters) => {
            return state.categories.filter((c: any) => getters.userCategoryIds.includes(c.id))
        },
        sponsoredCategoryIds: (state) => {
            return state.user ? state.user.company.categories : []
        },
        userCategoryIds: (state) => {
            return state.user && state.user.categories ? state.user.categories.map((c: Category) => c.id) : []
        },
        sponsoredCategories: (state): Category[] => {
            return state.categories.filter((c: Category) => state.user.company.categories.includes(c.id))
        },
        deals: (state) => {
            return state.user ? state.user.deals : []
        },
        tags: (state): Tag[] => {
            const tags: Tag[] = []

            state.categories.forEach((cat: Category) => {
                cat.tags.forEach((t: Tag) => {
                    if (!tags.find(a => a.id === t.id)) {
                        tags.push(t)
                    }
                })
            })

            return tags
        },
        isCompanyAdmin: (state) => state.user.roles.some((role: string) => ['Super Admin', 'Company Admin'].includes(role)),
        isAdmin: (state) => state.user.roles.some((role: string) => ['Super Admin'].includes(role)),
        shops: (state, getters) => {
            return state.suppliers.filter((supplier: Supplier) => {
                const ids = getters.shopCategories.map((c: any) => c.id)

                return !!supplier.categories.filter(value => ids.includes(value)).length
            })
        },
        theme: (state) => {
            return {
                primary: state.company.theme.color,
                radius: state.company.theme.radius + 'px'
            }
        },
        floatingCartItems: (state): CartItem[] => state.floatingCartItems
    },

    actions: {
        async registerServiceWorker({ state, dispatch }) {
            try {
                state.wb = new Workbox('/service-worker.js');

                state.wb.addEventListener('externalwaiting', event => {
                    state.newVersion = <ServiceWorker>event.sw
                });

                state.wb.addEventListener('externalactivated', () => {
                    window.location.reload();
                });

                state.sw = <ServiceWorkerRegistration>await state.wb.register()

                document.addEventListener('visibilitychange', () => {
                    dispatch('checkForUpdates')
                })

                setInterval(() => dispatch('checkForUpdates'), 60000);
            } catch (e) {
                console.log('error registering service worker', e);
            }
        },

        refreshUser({ commit }) {
            axios.get('/api/v2/me').then(r => commit('setUser', r.data.data));
        },

        checkForUpdates({ state }) {
            if (!state.sw) return;

            try {
                console.log('Check for updates')
                state.sw?.update();
            } catch (err) {
                console.log('sw.js could not be updated', err);
            }
        },

        init({ state, commit, dispatch }): void {
            axios.get('/sanctum/csrf-cookie');

            if (process.env.NODE_ENV !== 'development') {
                console.log('init')
                dispatch('registerServiceWorker')
            }

            if (state.user) {
                dispatch('fetchDigital')
            }
        },

        fetchDigital({ commit }): void {
            axios.all([
                axios.get('/api/v2/me/videos'),
                axios.get(`/api/v2/me/surveys`),
                axios.get(`/api/v2/me/rewards`)
            ]).then(axios.spread((...responses) => {
                commit('setVideos', responses[0].data.data)
                commit('setSurveys', responses[1].data.data)
                commit('setRewards', responses[2].data.data)
            })).catch((errors: any) => {
            })
        },

        updateApplication({ state }): void {
            if (state.newVersion) {
                state.newVersion.postMessage({ type: 'skipWaiting' })
            }
        },

        logout({ state }): void {
            axios.post('/api/v2/logout')
                .then(response => {
                    if (state.wb) {
                        state.wb.messageSW({ type: 'RECACHE_OFFLINE' })
                    }

                    window.location = '/login'
                })
        },

        toggleFavoriteLocation({ state, dispatch }, locationId): void {
            axios.patch(`/api/v2/me/locations`, {
                location: locationId
            }).then(r => dispatch('refreshUser'))
        },

        toggleFavoriteCategory({ state, dispatch }, categoryId): void {
            axios.patch(`/api/v2/me/categories`, {
                category: categoryId
            }).then(r => dispatch('refreshUser'))
        },

        loadLocations({ commit, state, dispatch }): void {
            if (process.env.NODE_ENV === 'development') {
                console.log('store::loadLocations')
            }

            axios.get('/api/v2/locations?include=media&sort=-created_at')
                .then(response => {
                    commit('setLocations', response.data.data)

                    const location = response.data.data.find((l: Location) => l.id === state.waitingLocation)

                    if (location) {
                        dispatch('setLocation', location)
                    }
                })
        },

        loadDashboard({ commit, state }): void {
            if (process.env.NODE_ENV === 'development') {
                console.log('store::loadDashboard')
            }

            const location = state.location

            axios.all([
                axios.get(`/api/v2/locations/${location.id}/categories?page[size]=50&include=loyalty_points,media,suppliers.media,suppliers.notes.fields,tags.collections.media&filter[types]=service,shop,challenge`),
                axios.get(`/api/v2/locations/${location.id}/posts?tag=office-information`),
                axios.get(`/api/v2/locations/${location.id}/posts?tag=!office-information`)
            ]).then(axios.spread((...responses) => {
                commit('setCategories', responses[0].data.data.map((c: any) => ({...c, suppliers: c.suppliers.map((s: any) => ({...s, notes: s.notes.map((n: ProductNote) => ({ ...n, fields: n.fields.map((f: NoteField) => ({ ...f, value: '', error: '' })) }))}))})))
                commit('setOfficeInformationPosts', responses[1].data.data.map((post: any) => ({ ...post, created_at: dayjs(post.created_at) })))
                commit('setPosts', responses[2].data.data.map((post: any) => ({ ...post, created_at: dayjs(post.created_at) })))
            })).catch((errors: any) => {
            })
        },

        setLocation({ commit, dispatch, state }, location: Location): any {
            if (process.env.NODE_ENV === 'development') {
                console.log('store::setLocation')
            }

            axios.put(`/api/v2/me`, {
                location_id: location.id
            }).then(() => {
                commit('setLocation', location)
            })
        },

        addCartItem({ commit }, product: CartItem) {
            commit('addCartItem', product)
        },

        updateCartItem({ commit }, product: CartItem) {
            commit('updateCartItem', product)
        },


        removeCartItem({ commit }, product: CartItem) {
            commit('removeCartItem', product)
        },

        updateCart({ commit }, cart: CartItem[]) {
            commit('updateCart', cart)
        }
    },

    mutations: {
        setWaitingLocation: (state, location: number): void => {
            state.waitingLocation = location
        },

        ready: (state): void => {
            state.ready = true
        },

        setVideos: (state, videos): void => {
            state.videos = videos
        },

        setRewards: (state, loyaltyProgram): void => {
            state.loyaltyProgram = loyaltyProgram
        },

        setSurveys: (state, surveys): void => {
            state.surveys = surveys
        },

        setPosts: (state, posts): void => {
            state.posts = posts
        },

        setOfficeInformationPosts: (state, posts): void => {
            state.officePosts = posts
        },

        setUser: (state, user): void => {
            state.user = user


            if (state.wb) {
                state.wb.messageSW({ type: 'RECACHE_OFFLINE' })
            }

            if (state.locale !== user.locale) {
                let nextUrl = <string>localStorage.getItem('nextUrl')

                if (nextUrl) {
                    localStorage.removeItem('nextUrl')

                    return location.replace(nextUrl)
                }

                location.reload();
            }

            if (!state.location || state.location.id !== user.location.id) {
                state.location = user.location
            }
        },

        setLocale: (state, locale): void => {
            localStorage.setItem('locale', locale)
            location.href = '?lang=' + locale;
        },

        closeNotice: (state): void => {
            localStorage.setItem('notice', 'false')
            state.notice = 'false'
        },

        setLocations: (state, locations): void => {
            if (process.env.NODE_ENV === 'development') {
                console.log('mutation::setLocations')
            }

            state.locations = locations
        },

        setLocation: (state, location: Location): void => {
            if (state.location.id !== location.id) {
                window.location.reload()
            }
        },

        setCategories: (state, categories): void => {
            state.categories = categories
        },

        setSuppliers: (state, suppliers): void => {
            state.suppliers = suppliers
        },

        addCartItem: (state, product: CartItem) => {
            const cartItem = state.floatingCartItems.find((item) => item.product_id === product.product_id)
            if (cartItem) {
                cartItem.quantity = cartItem.quantity + product.quantity
                cartItem.product.quantity = cartItem.quantity + product.quantity
            } else {
                state.floatingCartItems.push(product)
            }
        },
        updateCartItem: (state, cartItem: CartItem) => {
            const item = state.floatingCartItems.find((item) => item.product_id === cartItem.product_id)
            if (item) {
                item.quantity = cartItem.quantity
                item.product.quantity = cartItem.quantity
            } else {
                state.floatingCartItems.push(cartItem)
            }
        },
        removeCartItem: (state, product: CartItem) => state.floatingCartItems = state.floatingCartItems.filter((item) => product.product_id === item.product_id),
        updateCart: (state, cart: CartItem[]) => {
            state.floatingCartItems = cart
        }
    }
})
