/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Close Circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#231F20" d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm2.71 11.29a1 1 0 010 1.42 1 1 0 01-1.42 0L12 13.41l-1.29 1.3a1 1 0 01-1.42 0 1 1 0 010-1.42l1.3-1.29-1.3-1.29a1.004 1.004 0 011.42-1.42l1.29 1.3 1.29-1.3a1.004 1.004 0 011.42 1.42L13.41 12z"/>'
  }
})
