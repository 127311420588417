<template>
    <button class="justify-center w-full font-medium button-primary h-14 text-md disabled:bg-secondary" type="submit">
        <slot />
    </button>
</template>

<script lang="ts">
import Vue from 'vue'
import styled from 'vue-styled-components'

const StyledButton = styled.button`
    background: ${(props: Theme) => props.theme.primary};
    border-radius: ${(props: Theme) => props.theme.radius};

    &:hover {
        background: ${(props: Theme) => props.theme.primary};
    }
`

export default Vue.extend({
    components: {
        StyledButton
    }
})
</script>
