<template>
    <form @submit.prevent="$emit('submit')" novalidate>
        <header>
            <slot name="header" />
        </header>

        <slot />
    </form>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({

})
</script>