/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Add Circle Fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" _fill="#000" fill-rule="nonzero"/>'
  }
})
