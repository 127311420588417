<template>
    <auth-form @submit="handle">
        <template #header>
            <div v-if="!state" class="mb-10">
                <h1 class="text-5xl tracking-tight font-semibold font-display">{{ route === 'login' ? $t('Login') : $t('Create account') }}</h1>
                <h2 class="tracking-tight mt-2 text-md text-secondary" v-if="route === 'login'">
                    {{ $store.state.domain.registration ? $t('Don’t have an account yet?') : $t('Use the form below to login.') }}
                    <router-link class="underline text-brand" v-if="$store.state.domain.registration" :to="{name: 'register'}">{{ $t('Create account') }}</router-link>
                </h2>
                <h2 class="tracking-tight mt-2  text-md text-secondary" v-else>
                    {{ $store.state.domain.login ? $t('Already have an account?') : $t('Use the form below to register an account.') }}
                    <router-link class="underline text-brand" v-if="$store.state.domain.login" :to="{name: 'login'}">{{ $t('Login') }}</router-link>
                </h2>
            </div>
            <div v-else class="mb-10">
                <h1 class="text-5xl tracking-tight font-semibold font-display">{{ method === 'email' ? $t('Please check your inbox') : $t('Please check your phone') }}</h1>
                <h2 class="tracking-tight text-md text-secondary">{{ msg }} {{value}}.
                    <!-- <button class="font-medium underline text-brand text-md" @click="state = !state; timerCount = 0">{{ $t('Change') }}</button> -->
                </h2>
            </div>
        </template>

        <form-input autofocus :label="method === 'email' ? $t('Email') : $t('Phone')" :placeholder="method === 'email' ? $t('Enter your email address...') : $t('+31612345678')" :type="method === 'email' ? 'email' : 'tel'" :error="method === 'email' ? errors.email : errors.phone" v-model="value" v-if="!state" />

        <!-- Verification -->
        <form-input autofocus v-show="state" ref="password" :placeholder="$t('Verification code')" :label="$t('Verification code')" type="text" :error="errors.password" v-model="password" />

        <!-- / Verification -->
        <div class="flex items-center mt-3 font-medium tracking-tight text-secondary text-1sm" v-if="state">
            <h4>{{ $t("Didn't get the email? Check your spam folder or") }}
                <span class="ml-[2px]" v-if="timerCount > 0">
                    <icon class="!fill-secondary !square-14 -mt-px" name="Send Plane" />
                    <span class="-ml-px font-medium tracking-tight text-1sm text-secondary">{{ $t('Send again') }}<span class="text-sm"> ({{ timerCount }})</span></span>
                </span>
                <span class="ml-[2px]" v-if="timerCount <= 0" @click="state = !state">
                    <icon class="!fill-brand !square-14 -mt-px" name="Send Plane" />
                    <button class="-ml-px font-medium tracking-tight text-1sm text-brand">{{ $t('Send again') }}</button>
                </span>
            </h4>
        </div>

        <form-input class="mt-4" input-class="!border-0 !p-0 !rounded-none !tracking-tight !text-1sm !min-h-min" v-show="state && type !== 'login'" type="checkbox" v-model="tos">
            <i18n tag="span" :path="getTosText">
                <a slot="tos" class="font-medium underline text-brand" :href="$store.state.store.files.tos" target="_blank">{{ $t('Terms & Conditions') }}</a>
                <a slot="privacy" class="font-medium underline text-brand" :href="$store.state.store.files.privacy" target="_blank">{{ $t('Privacy Policy') }}</a>
            </i18n>
        </form-input>
        <!-- Buttons -->
        <div class="mt-6">
            <button class="justify-center w-full font-semibold button-primary h-14 text-md disabled:bg-secondary font-display" type="submit" :disabled="loading" @click="timerCount = 30">
                <span v-if="!waiting">{{ buttonText }}</span>
                <icon v-if="waiting" class="animate-spin square-24" name="Loader 3 Fill" />
            </button>
            <div v-if="route === 'login' && !state && $store.state.domain.login_with_sms" >
                <div class="flex flex-row w-full items-center">
                    <hr class="flex-grow h-px">
                    <div class="p-4 text-sm font-semibold">or</div>
                    <hr class="flex-grow h-px">
                </div>
                <button class="justify-center w-full font-semibold button h-14 text-md disabled:bg-secondary font-display" v-if="method === 'email'" color type="button" @click="method = 'sms'">{{ $t('Continue with SMS') }}</button>
                <button class="justify-center w-full font-semibold button h-14 text-md disabled:bg-secondary font-display" v-if="method !== 'email'" color type="button" @click="method = 'email'">{{ $t('Continue with Email') }}</button>
            </div>

        </div>

        <div  class="mt-4 text-center cursor-pointer" @click="toggleModal">
            <!-- <icon class="mr-2 -ml-2 square-16" name="Information Line" /> -->
            <span class="font-medium tracking-tight select-none text-1sm text-brand hover:underline">{{ $t('Learn more about this platform') }}</span>
        </div>

        <disclosure v-if="type != 'register' && state" icon-class="hidden" class="mt-4">
            <dt class="font-medium tracking-tight select-none text-1sm text-brand hover:underline" slot="header">{{ $t('How does login without a password work?') }}</dt>
            <dd class="pt-2 text-sm leading-6 tracking-tight text-secondary" slot="body">
                {{ $t("We'll send a code to the e-mail address you provided, the code will be valid for 10 minutes. This way you don't have to remember a password to log in.") }}
            </dd>
        </disclosure>

        <!-- / Buttons -->
        <!-- Modal -->
        <div v-if="modal" class="fixed inset-0 overflow-y-auto bg-brand-900/50 z-70 animate-fade backdrop-filter">
            <div class="absolute inset-0 flex justify-center" @click.self="toggleModal" ref="modal">
                <div class="flex flex-col min-h-screen">
                    <aside class="flex-grow" @click.self="toggleModal" ref="modal" />
                    <!-- modal -->
                    <main data-modal class="animate-modal max-w-[640px] w-[640px] rounded justify-between bg-white mx-auto md:mx-0 md:!min-w-screen md:w-screen md:max-w-screen md:rounded-b-none shadow">
                        <nav class="sticky inset-0 top-0 z-10 flex items-center h-16 border-b rounded-t bg-bg">
                            <h4 class="w-full text-lg font-bold tracking-tight text-center font-display" v-text="$t('About this platform')" />
                            <button class="absolute left-5 text-brand hover:text-primary" @click="toggleModal">
                                <icon width="22px" name="Arrow Left" />
                            </button>
                        </nav>
                        <!-- content -->
                        <section class="p-0 overflow-hidden text-base tracking-tight text-left">
                            <dl class="px-6 py-12">
                                <dt class="text-3xl font-semibold leading-10 tracking-tight font-display">{{ $store.state.domain.title }}</dt>
                                <dd class="max-w-lg tracking-tight text-md text-secondary">{{ $store.state.domain.description }}</dd>
                            </dl>
                            <shelf header-class="pr-6" class="p-6 pr-0 mt-4 border-t" no-container nav-top v-if="categories.length" :title="$t('Featured')" :subtitle="$t('Create your account to discover more.')" :large="1.4" :medium="1.4" :small="1.1">
                                <service-card v-for="c in categories.slice(0, 4)" :key="c.id" :title="c.name" :catchfrase="c.catchfrase" :image="c.image" :responsive="c.media.image ? c.media.image.responsive.default : c.image" />
                            </shelf>
                            <div class="p-6 pt-0">
                                <div class="justify-center w-full font-medium button-primary h-14 text-md disabled:bg-secondary" @click="toggleModal">{{ $t('Create account') }}</div>
                            </div>
                        </section>
                        <!-- /content -->
                    </main>
                    <!-- /modal -->
                    <aside class="flex-grow md:hidden" @click.self="toggleModal" ref="modal" />
                </div>
            </div>
        </div>
        <!-- / Modal -->
    </auth-form>
</template>

<script lang="ts">
import Vue from 'vue'
import axios from '@/services/Clients/AxiosClient'
import base64url from 'base64url'
import AuthButton from './Parts/Auth/AuthButton.vue'
import AuthForm from './Parts/Auth/AuthForm.vue'
import mapValues from 'lodash/mapValues'
import { TranslateResult } from 'vue-i18n'
import '@/icons/Arrow Forward'
import '@/icons/Alert Circle'
import '@/icons/Loader 3 Fill'
import '@/icons/Email'
import '@/icons/Send Plane'
import Disclosure from '@/components/Elements/Disclosure.vue'
import Shelf from '@/components/Shelf.vue'
import ServiceCard from '@/components/Cards/ServiceCard.vue'
import Error from '@/components/Error.vue'

interface Data {
    loading: boolean,
    waiting: boolean,
    done: boolean,
    error: string,
    state: string | null,
    type: string | null,
    errors: {},
    method: string,
    value: string,
    password: string,
    redirect: boolean,
    tos: boolean
    timerCount: number
    modal: boolean
    categories: {},
}

declare var data: any;

export default Vue.extend({
    components: {
        AuthButton, AuthForm,
        Disclosure, ServiceCard, Shelf,
        Error
    },

    data: (): Data => ({
        loading: false,
        waiting: false,
        state: null,
        type: null,
        done: true,
        error: '',
        method: 'email',
        errors: {},
        value: '',
        password: '',
        tos: false,
        redirect: false,
        timerCount: 0,
        modal: false,
        categories: [],
    }),

    created(): void {
        if (this.$store.state.store.slug === 'one2seat') {
            this.method = 'sms'
        }

        if (this.$route.name === 'register' && this.$store.state.domain.registration === false) {
            this.$router.push('login')
        }

        if (this.$route.name === 'login' && this.$store.state.domain.login === false) {
            this.$router.push('register')
        }

        this.type = this.$route.name === 'login' ? 'login' : 'register'

        let stateData: any = <string>new URLSearchParams(window.location.search).get('state')
        let email: any = <string>new URLSearchParams(window.location.search).get('email')
        let nonce: any = <string>new URLSearchParams(window.location.search).get('nonce')
        let type: any = <string>new URLSearchParams(window.location.search).get('type')
        let next: any = <string>new URLSearchParams(window.location.search).get('next')

        if (next) {
            localStorage.setItem('nextUrl', next)
        }

        if (email && nonce) {
            this.state = nonce
            this.value = email
            this.type = type
        }

        if (stateData) {
            try {
                stateData = JSON.parse(base64url.decode(stateData))

                this.state = stateData.state
                this.value = stateData.email
                this.password = stateData.password
                this.type = stateData.type
            } catch (e) {
                this.error = this.$t("The temporary password link is invalid or incomplete. Please request a new password.").toString()
            }
        }

        if (this.password) {
            this.$router.push('login')
            this.redirect = true

            if (this.type === 'login') {
                this.handle()
            }
        }

        if (data.error) {
            this.error = data.error
        }

        axios.get('/api/v2/categories?include=media')
            .then(response => this.categories = response.data.data)
    },
    watch: {

        timerCount: {
            handler(value) {

                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    },
    computed: {
        getTosText(): string {
            return this.$store.state.domain && this.$store.state.domain.tos ? this.$store.state.domain.tos : this.$t('I accept the {tos} and {privacy}').toString()
        },

        route(): string {
            return <string>this.$route.name
        },

        placeholderText(): TranslateResult {
            return this.type === 'register' ? this.$t('Paste sign up code') : this.$t('Paste login code')
        },

        buttonText(): TranslateResult {
            if (!this.state) {
                return this.method === 'email' ? this.$t('Login with Email') : this.$t('Login with SMS')
            }

            if (this.type === 'register') {
                return this.$t('Create account')
            }

            return this.$t('Submit verification code')
        },

        url(): string {
            return this.state ? '/api/v2/auth/password/login' : '/api/v2/auth/password'
        },

        formData(): any {
            if (this.state) {
                return {
                    state: this.state,
                    password: this.password,
                    tos: this.tos
                }
            }

            if (this.method !== 'email') {
                return {
                    phone: this.value
                }
            }

            return {
                email: this.value
            }
        },

        msg(): TranslateResult | void {
            const msg = new URLSearchParams(window.location.search).get('msg')

            if (msg === 'forgot') {
                return this.$t('Check your mailbox for furthur instructions.')
            }

            if (msg === 'reset') {
                return this.$t('Great! Your password has been reset and you can now login.')
            }

            if (!this.state) {
                return
            }

            if (this.redirect) {
                return
            }

            if (this.route === 'login') {
                return this.type === 'login'
                    ? this.$t('We just sent a code to')
                    : this.$t('There is no existing account under this email address. We just sent a code to')
            }

            return this.type === 'register'
                ? this.$t('We just sent a code to')
                : this.$t('It looks like you already have an account. We just sent a code to')

            return
        }
    },

    methods: {
        handle() {
            this.error = ''
            this.errors = {}

            if (this.loading) {
                return;
            }

            this.loading = true;

            setTimeout(() => {
                if (this.loading) {
                    this.waiting = true
                }
            }, 250)

            axios.post(this.url, this.formData)
                .then(response => {
                    if (!this.state) {
                        this.state = response.data.state
                        this.type = response.data.login ? 'login' : 'register'

                        return setTimeout(() => {
                            const passwordField = this.$refs.password as any
                            passwordField.$el.querySelector('input').focus()
                        }, 100)
                    }

                    this.$store.commit('setUser', response.data.data)
                    this.$store.commit('setLocation', response.data.data.location)

                    if (response.data.data.full_name === '') {
                        window.location.href = '/hello'

                        return
                    }

                    setTimeout(() => {
                        const url = localStorage.getItem('nextUrl') || this.$route.params.nextUrl || '/'
                        localStorage.removeItem('nextUrl')

                        window.location.href = url
                    }, 250);
                })
                .catch((error) => {
                    this.error = error.response?.data?.message

                    if (error.response?.data?.hasOwnProperty('errors')) {
                        this.errors = mapValues(error.response?.data?.errors, (e: string[]) => e[0])
                    }
                })
                .finally(() => {
                    this.loading = false
                    this.waiting = false
                    this.done = true
                })
        },
        toggleModal() {
            this.modal = !this.modal
        },
    }
})
</script>
