/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Save 2 Fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 21v-8H6v8H4c-.56 0-1-.45-1-1V4c0-.56.44-1 1-1h13l4 4v13c0 .55-.45 1-1 1h-2zm-2 0H8v-6h8v6z" _fill="#000" fill-rule="nonzero"/>'
  }
})
