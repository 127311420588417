/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Heart Mini': {
    width: 140,
    height: 140,
    viewBox: '0 0 140 140',
    data: '<path pid="0" d="M119.896 14.924A38.136 38.136 0 0071.89 25.69L70 27.86l-1.638-1.862a38.276 38.276 0 00-48.244-11.074 39.2 39.2 0 00-6.594 63.896l48.006 47.11a11.83 11.83 0 0016.87.07l47.698-46.83A39.2 39.2 0 00140 49.196a38.78 38.78 0 00-20.104-34.272z"/>'
  }
})
