/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Checkmark': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#231F20" d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 111.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33z"/>'
  }
})
