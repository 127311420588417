/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Close': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.41 12l4.3-4.29a1.004 1.004 0 00-1.42-1.42L12 10.59l-4.29-4.3a1.004 1.004 0 00-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 000 1.42 1 1 0 001.42 0l4.29-4.3 4.29 4.3a1 1 0 001.42 0 1 1 0 000-1.42z" _fill="#231F20"/>'
  }
})
