/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Loader 3 Fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.05 13.016h2.01l-.01-.01c.55 3.82 4.11 6.47 7.93 5.92a7.022 7.022 0 005.92-5.93h2.01-.01a9.008 9.008 0 01-9.95 7.94A9.014 9.014 0 013 12.986l.05.03zm0-2v-.01c.55-4.95 5-8.5 9.94-7.95 4.17.46 7.47 3.76 7.94 7.94h-2.02a7.02 7.02 0 00-7.94-5.93 7 7 0 00-5.93 5.92H3.02l.03.03z" _fill="#000" fill-rule="nonzero"/>'
  }
})
