/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flashlight-fill': {
    width: 1,
    height: 1,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="currentColor" d="M13 10h7l-9 13v-9H4l9-13z"/>'
  }
})
