<template>
    <div class="Flex__column" :class="getClasses">
        <slot />
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  props: {
    'md:third': Boolean,
    'md:fourth': Boolean,
    'md:half': Boolean,
  },

  computed: {
    getClasses(): string {
      var classes: string[] = []

      if (this.$props['md:third']) classes.push('md-third')
      if (this.$props['md:fourth']) classes.push('md-fourth')
      if (this.$props['md:half']) classes.push('md-half')

      return classes.map((item: string) => '--' + item).join(' ')
    }
  }
})
</script>
